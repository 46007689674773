import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { reactQueryAxios } from 'utils/axios';

type GetGroupBudgetParams = {
  groupId: number;
};

export interface MonthlyBreakdown {
  name: string;
  value: number;
}

export interface EventTypeBreakdown {
  name: string;
  value: number;
}

export interface ExpenseCategoryBreakdown {
  name: string;
  value: number;
}

export interface Event {
  actual_amount: number;
  budget_amount: number;
  items_count: number;
  event_type_id: number;
  id: number;
  name: string;
  start: string;
}

interface BudgetData {
  budget_period_end_date: string;
  budget_period_start_date: string;
  budget_period_total: number;
  budget_period_total_remaining: number;
  budget_period_total_spent: number;
  event_type_breakdown: EventTypeBreakdown[];
  expense_category_breakdown: ExpenseCategoryBreakdown[];
  monthly_breakdown: MonthlyBreakdown[];
}

interface EventsGroupData {
  events: Event[];
}

interface ResponseEventsGroupData {
  data: EventsGroupData;
}

interface ResponseBudgetData {
  data: BudgetData;
}

export const useGroupBudget = ({ groupId }: GetGroupBudgetParams) => {
  const queryKey = useMemo(() => ['group', groupId, 'budget'], [groupId]);

  const getGroupBudget = async ({
    apiClient,
    groupId,
  }: GetGroupBudgetParams & { apiClient: any }) =>
    apiClient({
      method: 'get',
      url: `/api/groups/${groupId}/analytics/events_with_budget`,
    });

  const getGroupBudgetPeriodBreakdown = async ({
    apiClient,
    groupId,
  }: GetGroupBudgetParams & { apiClient: any }) =>
    apiClient({
      method: 'get',
      url: `/api/groups/${groupId}/analytics/budget_period_breakdown`,
    });

  const retrieveBudget = useQuery<EventsGroupData, Error>({
    queryFn: () =>
      getGroupBudget({ apiClient: reactQueryAxios, groupId }).then(
        (response: ResponseEventsGroupData) => response.data
      ),
    queryKey,
  });

  const retrieveBudgetPeriodBreakdown = useQuery<BudgetData, Error>({
    queryFn: () =>
      getGroupBudgetPeriodBreakdown({
        apiClient: reactQueryAxios,
        groupId,
      }).then((response: ResponseBudgetData) => response.data),
    queryKey: [...queryKey, 'period-breakdown'],
  });

  return { retrieveBudget, retrieveBudgetPeriodBreakdown };
};
