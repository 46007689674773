import React, { useContext, useMemo, useState } from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { X } from '@phosphor-icons/react';
import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';

import { AuthContext, AuthContextType } from 'contexts/AuthContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import {
  axiosAuthenticated as axios,
  CustomAxiosRequestConfig,
} from 'utils/axios';
import { GoogleCalendarScopes } from 'utils/google';
import { useMicrosoftLogin } from 'utils/microsoft';

const CalendarBanner: React.FC = () => {
  const {
    onboard: { token: onboardToken },
    userProvider: { isOrganizerOrAdmin, setUser, user },
  } = useContext(AuthContext) as AuthContextType;

  const [organization] = useContext(OrganizationContext);
  const { enqueueSnackbar } = useSnackbar();

  const [, setIsLoading] = useState(false);
  const [dismissed, setDismissed] = useState<boolean>(false);

  // Makes this as a switch statement to add more calendar types in the future
  const needsCalendarAuth = useMemo(() => {
    if (organization && organization?.calendar_enabled?.length > 0) {
      switch (organization?.calendar_enabled) {
        case 'google':
          return !user?.has_google_calendar_auth;
        case 'outlook':
          return !user?.has_outlook_auth;
        default:
          return false;
      }
    }
    return false;
  }, [organization, user?.has_google_calendar_auth, user?.has_outlook_auth]);

  const setupGoogleCalendar = useGoogleLogin({
    flow: 'auth-code',
    onError: () => {
      enqueueSnackbar('Something went wrong! Please try again', {
        variant: 'error',
      });
    },
    onSuccess: (
      codeResponse: Omit<
        CodeResponse,
        'error' | 'error_description' | 'error_uri'
      >
    ) => {
      setIsLoading(true);
      axios(
        {
          data: {
            code: codeResponse.code,
            redirect_uri: window.location.origin,
          },
          method: 'POST',
          mode: 'CORS',
          url: '/api/auth_details/gcal',
        } as CustomAxiosRequestConfig,
        (res: { data: any }) => {
          setUser(res.data);
          enqueueSnackbar('Successfully connected to Google Calendar!', {
            variant: 'success',
          });
        },
        (error: AxiosError) => {
          setIsLoading(false);
          if (error.response) {
            const errorData = error.response.data as Pick<
              CodeResponse,
              'error' | 'error_description' | 'error_uri'
            >;
            if (
              (errorData.error && errorData.error === 'access_denied') ||
              errorData.error === 'invalid_scope'
            ) {
              enqueueSnackbar(
                'We were unable to connect to your calendar. Please try again and ensure calendar permissions are selected.',
                {
                  variant: 'error',
                }
              );
            } else {
              enqueueSnackbar('Something went wrong! Please try again', {
                variant: 'error',
              });
            }
          }
        }
      );
    },
    scope: GoogleCalendarScopes,
  });

  const handleMicrosoft = (code: string, doCleanup: any) => {
    // Refresh auth details
    setIsLoading(true);
    axios(
      {
        data: { code, redirect_uri: window.location.origin + '/auth/outlook' },
        method: 'POST',
        mode: 'CORS',
        url: '/api/auth_details/outlook',
      } as CustomAxiosRequestConfig,
      (res) => {
        setUser(res.data);
        enqueueSnackbar('Successfully connected to Outlook Calendar!', {
          variant: 'success',
        });
        doCleanup();
      },
      () => {
        setIsLoading(false);
        enqueueSnackbar('Something went wrong! Please try again', {
          variant: 'error',
        });
        doCleanup();
      }
    );
  };

  const { signIn } = useMicrosoftLogin(
    organization,
    undefined,
    handleMicrosoft,
    onboardToken
  );

  const handleSetUp = () => {
    organization?.calendar_enabled === 'google'
      ? setupGoogleCalendar()
      : signIn();
  };

  const handleClose = () => {
    setDismissed(true);
  };

  if (!needsCalendarAuth || dismissed || !isOrganizerOrAdmin) return null;

  return (
    <Box
      alignItems='center'
      display='flex'
      height='5vh'
      justifyContent='center'
      sx={{ backgroundColor: '#F4B63F', padding: '0 1rem' }}
      width='100%'
    >
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Stack alignItems='center' direction='row' spacing={2}>
          <Typography>
            {`You are not connected to ${
              organization?.calendar_enabled === 'google' ? 'Google' : 'Outlook'
            } calendar. Connect now to unlock
            calendar invitations!`}
          </Typography>
          <Button color='primary' onClick={handleSetUp} variant='text'>
            Connect now
          </Button>
        </Stack>

        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <X />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CalendarBanner;
