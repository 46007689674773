import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { MinusCircle } from '@phosphor-icons/react';
import { sortBy, uniqBy } from 'lodash';
import { useSnackbar } from 'notistack';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import DeleteModal from 'components/Core/DeleteModal';
import Search from 'components/Core/Search';
import UnsavedChangesModal from 'components/Core/UnsavedChangesModal';
import SharedCalendarList from 'components/Settings/Integrations/SharedCalendarList';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { searchFilter } from 'utils/calendar';

const SharedCalendarModal = ({
  axios,
  calendars,
  handleClose,
  providerName,
  show,
}) => {
  const [org, setOrg] = useContext(OrganizationContext);

  const { enqueueSnackbar } = useSnackbar();

  const [isWorking, setIsWorking] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [selectedCalendars, setSelectedCalendars] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [toDelete, setToDelete] = useState(null);
  const [tryClose, setTryClose] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    if (!showDelete && setToDelete !== null) {
      // This prevents the "delete" modal from flashing "Undefined" before closing
      setTimeout(() => setToDelete(null), 500);
    }
  }, [showDelete]);

  const hasCalendarsToAdd = useMemo(() => calendars?.length > 0, [calendars]);

  const filteredCalendars = useMemo(() => {
    const cals = uniqBy(
      [...(org.calendars || []), ...calendars],
      'calendar_id'
    );
    return cals
      ? sortBy(
          cals?.filter((cal) =>
            searchVal ? searchFilter(searchVal, cal, 'name') : true
          ),
          (cc) => cc?.name?.toLowerCase()
        )
      : [];
  }, [calendars, org.calendars, searchVal]);

  const connectedCalendars = useMemo(
    () => filteredCalendars?.filter((cal) => cal.id !== cal.calendar_id),
    [filteredCalendars]
  );

  const otherCalendars = useMemo(
    () => filteredCalendars?.filter((cal) => cal.id === cal.calendar_id),
    [filteredCalendars]
  );

  const startDelete = (calendar) => {
    const orgCalendar = org?.calendars?.find(
      (c) => c.calendar_id === calendar.id
    );
    setToDelete(orgCalendar || calendar);
    setShowDelete(true);
  };

  const handleToggle = (cal) => {
    const currentIndex = selectedCalendars.findIndex((c) => c.id === cal.id);
    const newChecked = [...selectedCalendars];
    if (currentIndex === -1) {
      newChecked.push({ id: cal.id, name: cal.name });
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedCalendars(newChecked);
  };

  const closeModal = useCallback(() => {
    setSelectedCalendars([]);
    setSearchVal('');
    handleClose();
  }, [handleClose]);

  const addCalendars = useCallback(() => {
    setIsWorking(true);
    return axios(
      {
        data: { calendars: selectedCalendars },
        method: 'post',
        url: '/api/shared_calendars',
      },
      (res) => {
        setOrg(res.data);
        closeModal();
        setIsWorking(false);
        enqueueSnackbar(
          `${pluralize(
            'shared calendar',
            selectedCalendars?.length,
            true
          )} connected!`,
          { variant: 'success' }
        );
      },
      () => {
        setIsWorking(false);
        enqueueSnackbar('Something went wrong! Please try again', {
          variant: 'error',
        });
      }
    );
  }, [axios, closeModal, enqueueSnackbar, selectedCalendars, setOrg]);

  const deleteCalendar = useCallback(
    (modalCallback) => {
      if (toDelete) {
        setIsWorking(true);
        return axios(
          {
            method: 'delete',
            url: `/api/shared_calendars/${toDelete.id}`,
          },
          (res) => {
            setOrg(res.data);
            modalCallback();
            setShowDelete(false);
            setIsWorking(false);
            enqueueSnackbar(`"${toDelete.name}" disconnected!`, {
              variant: 'success',
            });
          },
          () => {
            modalCallback();
            setIsWorking(false);
            enqueueSnackbar('Something went wrong, please try again.', {
              variant: 'error',
            });
          }
        );
      }
    },
    [axios, enqueueSnackbar, setOrg, toDelete]
  );

  const checkUnsavedBeforeClosing = () =>
    selectedCalendars?.length > 0 ? setTryClose(true) : closeModal();

  return (
    <>
      <Dialog
        aria-label={`Manage your ${providerName} shared calendars`}
        onClose={checkUnsavedBeforeClosing}
        open={show}
        scroll='paper'
        sx={{ minHeight: '70vh' }}
      >
        <DialogTitle>
          <Typography variant='inherit'>
            Manage your {providerName} shared calendars
          </Typography>
          <Typography display='block' sx={{ mb: 2 }} variant='overline'>
            {[
              hasCalendarsToAdd
                ? 'Select the calendars you would like to connect'
                : connectedCalendars?.length > 0
                ? 'These calendars are connected'
                : 'Contact the calendar admin to connect calendars',
              ' to Five To Nine.',
              `You${
                connectedCalendars?.length < 1 ? '’ll be able to' : ' can'
              } add events to connected calendars.`,
            ].join('')}
          </Typography>
          <Search
            fullWidth
            label='Search calendars'
            onChange={(e) => setSearchVal(e.target.value)}
            placeholder='Search'
            value={searchVal}
          />
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          {/* <DialogContentText>{JSON.stringify(org.calendars)}</DialogContentText> */}
          <List data-testid='TODO:DATA-LIST-84214' disablePadding>
            {connectedCalendars?.length > 0 && (
              <SharedCalendarList
                cals={connectedCalendars}
                label={`Connected ${pluralize(
                  'calendar',
                  connectedCalendars?.length
                )}`}
                showCount
              >
                {connectedCalendars?.map((cal) => (
                  <ListItem disableGutters disablePadding key={cal?.id}>
                    <ListItemButton
                      onClick={() => startDelete(cal)}
                      role={undefined}
                      sx={{ pl: 3 }}
                    >
                      <ListItemText
                        id={`${cal?.id}-checkbox-label`}
                        primary={cal?.name}
                        primaryTypographyProps={{ variant: 'body1' }}
                      />
                      <ListItemIcon slot='end'>
                        <MinusCircle
                          color={theme.palette.error.main}
                          size={20}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                ))}
              </SharedCalendarList>
            )}
            {(otherCalendars?.length > 0 || filteredCalendars.length === 0) && (
              <SharedCalendarList
                cals={otherCalendars}
                label={`${otherCalendars.length > 0 ? 'Other c' : 'C'}alendars`}
              >
                {otherCalendars?.map((cal) => (
                  <ListItem disableGutters disablePadding key={cal?.id}>
                    <ListItemButton
                      onClick={() => handleToggle(cal)}
                      role={undefined}
                      sx={{ px: 3 }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          checked={
                            selectedCalendars?.findIndex(
                              (sc) => sc.id === cal?.id
                            ) !== -1
                          }
                          data-testid='TODO:DATA-CHECKBOX-82435'
                          edge='start'
                          inputProps={{
                            'aria-labelledby': `${cal?.name}-checkbox-label`,
                          }}
                          sx={{ ml: '-6px' }}
                          tabIndex={-1}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={`${cal?.name}-checkbox-label`}
                        primary={cal?.name}
                        primaryTypographyProps={{ variant: 'body1' }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </SharedCalendarList>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            data-testid='cancel'
            onClick={checkUnsavedBeforeClosing}
            variant='text'
          >
            {selectedCalendars?.length === 0 ? 'Close' : 'Cancel'}
          </Button>
          <LoadingButton
            color='primary'
            data-testid='connect-selected'
            disabled={selectedCalendars?.length === 0 || isWorking}
            loading={isWorking}
            onClick={addCalendars}
            variant='contained'
          >
            Connect selected {pluralize('calendar', selectedCalendars?.length)}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <DeleteModal
        noun='calendar'
        onCancel={() => setShowDelete(false)}
        onDelete={deleteCalendar}
        owner='Five to Nine'
        show={showDelete}
        subject={`"${toDelete?.name}"`}
        verb='Disconnect'
      />
      <UnsavedChangesModal
        hasUnsavedChanges={selectedCalendars?.length > 0}
        onCancel={() => setTryClose(false)}
        onConfirm={() => {
          setTryClose(false);
          closeModal();
        }}
        tryClose={tryClose}
      />
    </>
  );
};

SharedCalendarModal.propTypes = {
  axios: PropTypes.func,
  calendars: PropTypes.shape({
    length: PropTypes.number,
  }),
  handleClose: PropTypes.func,
  providerName: PropTypes.any,
  show: PropTypes.any,
};

export default SharedCalendarModal;
