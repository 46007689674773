import { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  AlertTitle,
  Button,
  Collapse,
  Grid,
  IconButton,
  Stack,
} from '@mui/material';
import { Sparkle, X } from '@phosphor-icons/react';
import PropTypes from 'prop-types';

export const SlackPrivateChannelTipStorageKey = 'slack-alert-dismissed';
const AlertText = {
  invitations:
    'You can now access and send invitations to private Slack channels!',
  settings:
    'You can now easily sync your private Slack channels to Five to Nine',
};
const AlertSubtext = {
  invitations: null,
  settings:
    'To learn how to access and send invitations to private Slack channels, click on "Learn more"',
};

const SlackPrivateChannelTip = ({ fromInvitations }) => {
  const [isAlertDismissed, setAlertDismissed] = useState(
    localStorage.getItem(SlackPrivateChannelTipStorageKey) || false
  );

  const message = useMemo(
    () => ({
      subtext: AlertSubtext[fromInvitations ? 'invitations' : 'settings'],
      text: AlertText[fromInvitations ? 'invitations' : 'settings'],
    }),
    [fromInvitations]
  );

  useEffect(() => {
    const storedDismissedValue = localStorage.getItem(
      SlackPrivateChannelTipStorageKey
    );
    if (storedDismissedValue !== null) {
      setAlertDismissed(storedDismissedValue === 'true');
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(SlackPrivateChannelTipStorageKey, isAlertDismissed);
  }, [isAlertDismissed]);

  const collapseComponent = useMemo(
    () => (
      <Collapse in={!isAlertDismissed} unmountOnExit>
        <Alert
          action={
            <Stack alignItems='center' direction='row'>
              <Button
                data-testid='learn-more'
                href='https://www.notion.so/fivetonine/Sync-Slack-channels-14d77a18e8a280679321d3fbefebd221'
                rel='noopener'
                size='small'
                sx={{ color: 'info.dark', whiteSpace: 'nowrap' }}
                target='_blank'
              >
                Learn more
              </Button>
              <IconButton
                aria-label='close'
                onClick={() => {
                  setAlertDismissed(true);
                }}
                size='small'
              >
                <X />
              </IconButton>
            </Stack>
          }
          data-testid='TODO:DATA-ALERT-47247'
          iconMapping={{
            info: <Sparkle size={24} weight='fill' />,
          }}
          severity='info'
        >
          <AlertTitle>{message.text}</AlertTitle>
          {message.subtext}
        </Alert>
      </Collapse>
    ),
    [isAlertDismissed, message]
  );

  return fromInvitations ? (
    <Grid
      item
      sx={{
        ...(isAlertDismissed ? { pt: '0 !important' } : {}),
        transition: 'all 200ms ease-in-out',
      }}
      xs={12}
    >
      {collapseComponent}
    </Grid>
  ) : (
    collapseComponent
  );
};

SlackPrivateChannelTip.propTypes = {
  fromInvitations: PropTypes.bool,
};

export default SlackPrivateChannelTip;
