import React, { useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import IntegrationCard from 'components/Settings/Integrations/IntegrationCard';
import SharedCalendarModal from 'components/Settings/Integrations/SharedCalendarModal';
import { AuthContext } from 'contexts/AuthContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { axiosAuthenticated as axios } from 'utils/axios';
import { useMicrosoftLogin } from 'utils/microsoft';

const OutlookSharedCalendar = ({ startEditing }) => {
  const {
    onboard: { token: onboardToken },
    userProvider: { setUser },
  } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [org, setOrg] = useContext(OrganizationContext);
  const { enqueueSnackbar } = useSnackbar();
  const [showCalModal, setShowCalModal] = useState(false);
  const [calendars, setCalendars] = useState([]);

  const getCalendars = () => {
    setIsLoading(true);
    axios(
      {
        method: 'get',
        url: '/api/shared_calendars',
      },
      (res) => {
        setCalendars(
          res.data.map((cal) => ({
            ...cal,
            calendar_id: cal.id,
          }))
        );
        setShowCalModal(true);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
        enqueueSnackbar('Something went wrong! Please try again', {
          variant: 'error',
        });
      }
    );
    // finally:
    setIsLoading(false);
  };

  const handleMicrosoft = (code, doCleanup) => {
    // Refresh auth details
    setIsLoading(true);
    axios(
      {
        data: { code, redirect_uri: window.location.origin + '/auth/outlook' },
        method: 'POST',
        mode: 'CORS',
        url: '/api/auth_details/outlook',
      },
      (res) => {
        setUser(res.data);
        getCalendars();
        doCleanup();
      },
      () => {
        setIsLoading(false);
        enqueueSnackbar('Something went wrong! Please try again', {
          variant: 'error',
        });
        doCleanup();
      }
    );
  };

  const { signIn } = useMicrosoftLogin(
    org,
    undefined,
    handleMicrosoft,
    onboardToken
  );

  return (
    <>
      <IntegrationCard
        description="Sign into Five to Nine via Outlook SSO to enable exporting events to your organization's Shared Calendars."
        imageName='outlook-cal'
        isActive={org.calendars?.length > 0}
        name='Outlook Calendar'
        onEdit={() => getCalendars()}
        onSetUp={() => signIn()}
      />
      <SharedCalendarModal
        axios={axios}
        calendars={calendars}
        handleClose={() => setShowCalModal(false)}
        org={org}
        providerName='Outlook'
        setOrg={setOrg}
        show={showCalModal}
      />
    </>
  );
};

OutlookSharedCalendar.propTypes = {
  startEditing: PropTypes.any,
};
export default OutlookSharedCalendar;
