import React, { useContext, useMemo } from 'react';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  IconButton,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { Hash, LockSimple } from '@phosphor-icons/react';

import { SyncedSlackChannelContext } from 'contexts/SyncedSlackChannelContext';

export interface GroupCreateSlackChannel {
  id: string;
  is_private: boolean;
  label: string;
}

interface Props {
  channel: GroupCreateSlackChannel | null;
  setChannel: (channel: GroupCreateSlackChannel | null) => void;
}

const GroupCreateChannel: React.FC<Props> = ({ channel, setChannel }) => {
  const [syncedChannels, , isSyncedChannelLoading] = useContext(
    SyncedSlackChannelContext
  );

  const channelOptions = useMemo(
    () =>
      syncedChannels
        .filter(
          (c: { synced_group_id: number | null }) => c.synced_group_id == null
        )
        .map((c: { id: string; name: string; private: boolean }) => ({
          id: c.id,
          is_private: c.private,
          label: c.name,
        })),
    [syncedChannels]
  );

  return (
    <Stack spacing={2}>
      <Typography>
        Choose the Slack channel from which you want to synchronize your group
        members. If the Slack channel you&apos;re looking for isn&apos;t listed
        below, learn how to access it by clicking{' '}
        <Link
          href='https://www.notion.so/fivetonine/Sync-a-Slack-channel-to-a-Group-14d77a18e8a280b6bd09e453d718a099'
          target='_blank'
          underline='none'
        >
          here
        </Link>
        .
      </Typography>
      <Autocomplete
        id='slack-channel-select'
        loading={isSyncedChannelLoading}
        loadingText='Loading Slack channels...'
        onChange={(_e, value) => setChannel(value)}
        options={channelOptions}
        renderInput={(props: AutocompleteRenderInputParams) => {
          if (channel) {
            props.InputProps.startAdornment = channel.is_private ? (
              <LockSimple size={20} />
            ) : (
              <Hash size={20} />
            );
          }
          return <TextField placeholder='Select a Slack channel' {...props} />;
        }}
        renderOption={(props, option) => (
          <Box component='li' {...props}>
            <IconButton>
              {option.is_private ? (
                <LockSimple size={20} />
              ) : (
                <Hash size={20} />
              )}
            </IconButton>
            {option.label}
          </Box>
        )}
        value={channel}
      />
    </Stack>
  );
};

export default GroupCreateChannel;
