export const NpsAccent = (score) => {
  if (score <= 0) {
    return 'error';
  } else if (score > 0 && score <= 10) {
    return 'otherOrange';
  } else if (score > 10 && score <= 50) {
    return 'otherYellow';
  } else {
    return 'success';
  }
};
