import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Card, Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import dayjs from 'dayjs';
import * as _ from 'lodash';

import Engagement from 'components/Analytics/Engagement';
import EventTable from 'components/Analytics/EventTable';
import Metrics from 'components/Analytics/Metrics';
import NpsCard from 'components/Analytics/NpsCard';
import Search from 'components/Core/Search';
import Filters from 'components/shared/Filters';
import DefaultLayout from 'components/shared/layout/DefaultLayout';
import { AnalyticsContext } from 'contexts/AnalyticsContext';
import { GroupsContext } from 'contexts/GroupsContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import {
  GroupFilter,
  OfficeFilter,
  SearchFilter,
  TypeFilter,
} from 'utils/event';

const timeOptions = [
  { id: 'week', name: 'Past week' },
  { id: 'month', name: 'Past month' },
  { amount: 3, id: 'three', name: 'Past 3 months', unit: 'month' },
  { id: 'year', name: 'Past year' },
  { id: 'custom', name: 'Custom range', type: 'date-range' },
];

const Analytics = () => {
  const dateTimeFormat = 'YYYY-MM-DD hh:mm:ss';
  const today = dayjs().format(dateTimeFormat);

  const { events, isLoading, requestEvents } = useContext(AnalyticsContext);
  const [groupsFromContext] = useContext(GroupsContext);
  const [organization] = useContext(OrganizationContext);

  const [filtGroupIds, setFiltGroupsIds] = useState([]);
  const [filtOfficeIds, setFiltOfficeIds] = useState([]);
  const [filtTypeIds, setFiltTypesIds] = useState([]);
  const [filtTimeRange, setFiltTimeRange] = useState(null);
  const [searchVal, setSearchVal] = useState('');

  useEffect(() => {
    if (!isLoading && !events?.length) {
      requestEvents({
        end: today,
        start: dayjs().subtract(3, 'month').format(dateTimeFormat),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredEvents = useMemo(() => {
    const evs = _.filter(
      events,
      (ev) =>
        SearchFilter(ev, searchVal) &&
        OfficeFilter(ev, filtOfficeIds) &&
        TypeFilter(ev, filtTypeIds) &&
        GroupFilter(ev, filtGroupIds)
    );
    return evs;
  }, [events, searchVal, filtOfficeIds, filtTypeIds, filtGroupIds]);

  const isFiltering = useMemo(
    () =>
      (filtTimeRange ? 1 : 0) +
        filtGroupIds?.length +
        filtOfficeIds?.length +
        filtTypeIds?.length +
        searchVal?.length >
      0,
    [filtGroupIds, filtOfficeIds, filtTimeRange, filtTypeIds, searchVal]
  );

  const handleTimeFilter = (val) => {
    if (val && val.data?.start && val.data?.end && val.value[0] === 'custom') {
      // custom range option selected
      const newTimeRange = {
        end: val.data.end.format(dateTimeFormat),
        start: val.data.start.format(dateTimeFormat),
      };
      setFiltTimeRange(newTimeRange);
      requestEvents(newTimeRange);
    } else if (val && _.isArray(val) && val.length > 0) {
      // other range option selected
      const newTimeFilterName = val[0];
      const {
        amount = 1,
        id,
        unit,
      } = timeOptions.find((o) => o.id === newTimeFilterName);
      const newTimeRange = {
        end: today,
        start: dayjs()
          .subtract(amount, unit || id)
          .format(dateTimeFormat),
      };
      setFiltTimeRange(newTimeRange);
      requestEvents(newTimeRange);
    } else {
      // default range
      setFiltTimeRange(null);
      requestEvents({
        end: today,
        start: dayjs().subtract(3, 'month').format(dateTimeFormat),
      });
    }
  };

  return (
    <DefaultLayout
      fixedContent={
        organization ? (
          <Filters
            filters={[
              {
                handleChange: setFiltOfficeIds,
                label: 'Office',
                options: organization?.offices || [],
              },
              {
                handleChange: setFiltTypesIds,
                label: 'Event type',
                options: organization?.event_types || [],
              },
              {
                handleChange: setFiltGroupsIds,
                label: 'Group',
                options: groupsFromContext || [],
              },
              {
                defaultValueId: 'three',
                handleChange: handleTimeFilter,
                label: 'Time range',
                manuallySorted: true,
                options: timeOptions,
                type: 'single',
              },
            ]}
            handleClearAll={handleTimeFilter}
          >
            <Search
              label='Search events'
              onChange={(e) => setSearchVal(e.target.value)}
              placeholder='Search events'
              value={searchVal}
            />
          </Filters>
        ) : (
          <Skeleton height={44} width='50vw' />
        )
      }
      fixedLocation='below-tabs'
      title='Analytics'
    >
      <Grid container direction='row' spacing={3}>
        <Grid
          alignItems='stretch'
          display='flex'
          flexDirection='column'
          size={{ xs: 12, md: 7 }}
        >
          <NpsCard
            events={filteredEvents}
            isCumulative
            isFiltering={isFiltering}
            isLoading={isLoading}
            noEvents={filteredEvents?.length === 0}
          />
        </Grid>
        <Grid container direction='column' size={{ xs: 12, md: 5 }} spacing={2}>
          <Grid size={{ xs: 12 }}>
            <Engagement events={filteredEvents} isLoading={isLoading} />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Metrics events={filteredEvents} isLoading={isLoading} />
          </Grid>
        </Grid>
      </Grid>
      <Card data-testid='TODO:DATA-CARD-51757' sx={{ mt: 3 }}>
        <EventTable events={filteredEvents} isLoading={isLoading} />
      </Card>
    </DefaultLayout>
  );
};

export default Analytics;
