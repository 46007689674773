import React, { useContext, useMemo } from 'react';
import {
  Controller,
  ControllerRenderProps,
  UseFormReturn,
} from 'react-hook-form';
import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  ListItemButton,
  ListItemButtonProps,
  ListItemText,
  TextField,
} from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useSnackbar } from 'notistack';

import {
  CommunicationFormData,
  InvitationFormCalendar,
} from 'components/Events/Controls/Communications/communication.types';
import { AuthContext, AuthContextType } from 'contexts/AuthContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import FtnEvent from 'types/FtnEvent';
import { getPersonalCalendar } from 'utils/calendar';

interface Props {
  form: UseFormReturn<CommunicationFormData>;
  savedEvent: FtnEvent;
}

const InvitationSelectCalendars = ({ form: { control, watch } }: Props) => {
  const [org] = useContext(OrganizationContext);
  const {
    userProvider: { user },
  } = useContext(AuthContext) as AuthContextType;
  const { enqueueSnackbar } = useSnackbar();

  const allCalendars = useMemo(
    () => [
      ...(user ? [getPersonalCalendar(user)] : []),
      ...(org?.calendars ? org.calendars : []),
    ],
    [org, user]
  );

  const updateCalendars = (
    selectedCals: InvitationFormCalendar[],
    reason: string,
    option: InvitationFormCalendar,
    field: ControllerRenderProps<CommunicationFormData, 'calendars'>
  ) => {
    const hasNoSelections = selectedCals?.length === 0;
    if (hasNoSelections) {
      enqueueSnackbar('Unable to remove. You need at least 1 calendar!', {
        variant: 'error',
      });
    } else {
      const cals = selectedCals;
      if (reason === 'removeOption' && org?.calendars?.length > 1) {
        cals.push({
          ...option,
          is_deleted: true,
        });
      }
      field.onChange(cals);
    }
  };

  const selectedCalendars = watch('calendars')?.filter((c) => !c.is_deleted);

  return (
    <GoogleOAuthProvider
      clientId={import.meta.env.VITE_GOOGLE_CALENDAR_CLIENT_ID}
    >
      <FormControl fullWidth sx={{ m: 0, mb: 2 }}>
        <Controller
          control={control}
          defaultValue={selectedCalendars}
          name='calendars'
          render={({ field }) => (
            <Autocomplete
              data-testid='select-calendars'
              disableClearable
              disableCloseOnSelect
              fullWidth
              getOptionLabel={(option) => option?.name}
              isOptionEqualToValue={(option, value) =>
                option?.calendar_id === value?.id || option?.id === value?.id
              }
              limitTags={2}
              multiple
              onChange={(e, val, reason, option) =>
                updateCalendars(val, reason, option?.option, field)
              }
              options={allCalendars}
              renderInput={(params) => (
                <TextField
                  {...params}
                  data-testid='select-calendars-input'
                  helperText={null}
                />
              )}
              renderOption={(props, option, state) => (
                <ListItemButton
                  {...(props as ListItemButtonProps)}
                  disableGutters
                  key={`${option?.id}-${option?.name}`}
                >
                  <Checkbox
                    checked={state.selected}
                    data-testid='select-calendar-option-checkbox'
                    value={option}
                  />
                  <ListItemText primary={option?.name} sx={{ m: 0 }} />
                </ListItemButton>
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    {...(tagValue.length > 1 ? getTagProps({ index }) : null)}
                    key={index}
                    label={option.name}
                  />
                ))
              }
              sx={{ '& .MuiFormControl-root': { mt: 0 } }}
              value={selectedCalendars}
            />
          )}
        />

        {org?.calendar_enabled !== 'outlook' && (
          <Controller
            control={control}
            name='notifies'
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value}
                    data-testid='send-email-checkbox'
                    name='Notify guests via email'
                    onChange={(e) => field.onChange(e.target.checked)}
                    sx={{ ml: 1 }}
                  />
                }
                key='notify-via-email'
                label='Send an email invitation in addition to the calendar invitation to notify the guests'
              />
            )}
          />
        )}
      </FormControl>
    </GoogleOAuthProvider>
  );
};

export default InvitationSelectCalendars;
