import React from 'react';
import { Chip, SxProps, Tooltip, Typography } from '@mui/material';
import { SlackLogo } from '@phosphor-icons/react';

interface SSChipProps {
  size?: 'small' | 'medium';
  sx?: SxProps;
}

const SlackSyncedChip: React.FC<SSChipProps> = ({ size, sx = {} }) => (
  <Chip
    color='info'
    icon={<SlackLogo size={18} />}
    label={
      <Tooltip placement='top' title='Group members are synced from Slack.'>
        <Typography
          sx={{
            fontWeight: 400,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          variant='h6'
        >
          Synced
        </Typography>
      </Tooltip>
    }
    size={size}
    sx={sx}
    variant='light'
  />
);

export default SlackSyncedChip;
