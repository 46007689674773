import React, { useCallback, useContext, useMemo } from 'react';
import {
  Box,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import { Smiley, SmileyMeh, SmileySad } from '@phosphor-icons/react';
import { PropTypes } from 'prop-types';

import ChartTooltip from 'components/Events/Controls/Analytics/Charts/ChartTooltip';
import CircularProgressWithLabel from 'components/shared/CircularProgressWithLabel';
import { AnalyticsContext } from 'contexts/AnalyticsContext';
import { CalculateNPS } from 'utils/event';
import { NpsScoreDescriptions } from 'utils/nps_score';

const NpsChart = ({
  events,
  height = 300,
  isCumulative = false,
  isFiltering = false,
  isSingleEvent = false,
  noEvents,
}) => {
  const { isLoading } = useContext(AnalyticsContext);
  const theme = useTheme();
  const isUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const npsTotals = useMemo(
    () =>
      events?.length > 0 || (noEvents && isFiltering)
        ? CalculateNPS(events)
        : null,
    [events, isFiltering, noEvents]
  );

  const data = useMemo(
    () =>
      [
        {
          colorName: 'success',
          Icon: Smiley,
          id: 'pos',
          label: 'Promoters',
          percent: `${Math.floor(
            (npsTotals?.promoterCount / npsTotals?.totalCount) * 100
          )}%`,
          value: npsTotals?.promoterCount,
        },
        {
          colorName: 'warning',
          Icon: SmileyMeh,
          id: 'passive',
          label: 'Passive',
          percent: `${Math.floor(
            (npsTotals?.passiveCount / npsTotals?.totalCount) * 100
          )}%`,
          value: npsTotals?.passiveCount,
        },
        {
          colorName: 'error',
          Icon: SmileySad,
          id: 'neg',
          label: 'Detractors',
          percent: `${Math.floor(
            (npsTotals?.detractorCount / npsTotals?.totalCount) * 100
          )}%`,
          value: npsTotals?.detractorCount,
        },
      ].map((item) => ({
        ...item,
        color: theme.palette[item.colorName]?.main,
        total: npsTotals?.totalCount,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      events?.length,
      npsTotals,
      npsTotals?.detractorCount,
      npsTotals?.passiveCount,
      npsTotals?.promoterCount,
      npsTotals?.totalCount,
      theme.palette,
    ]
  );

  const noData = useMemo(
    () =>
      !isLoading &&
      ((isSingleEvent && (!data || data?.length === 0)) ||
        !npsTotals ||
        npsTotals?.totalCount === 0 ||
        events?.length === 0),
    [isLoading, isSingleEvent, data, npsTotals, events?.length]
  );

  const npsScore = useMemo(
    () =>
      npsTotals &&
      (npsTotals.nps || npsTotals.nps === 0) &&
      Math.round(npsTotals.nps * 100),
    [npsTotals]
  );

  const npsScoreDescription = useMemo(
    () =>
      NpsScoreDescriptions({ isSingleEvent })?.find(
        (d) => d.describes(npsScore) === true
      ),
    [npsScore, isSingleEvent]
  );

  const centeredMetric = useCallback(
    ({ centerX, centerY }) => (
      <text
        dominantBaseline='central'
        style={{
          fontSize: '30px',
          fontWeight: '600',
        }}
        textAnchor='middle'
        x={centerX}
        y={isCumulative ? centerY : centerY - 20}
      >
        {isNaN(npsScore) ? '' : npsScore}
      </text>
    ),
    [isCumulative, npsScore]
  );

  return (
    <Stack
      alignItems='center'
      columnGap={isCumulative ? 2 : 8}
      direction={{ lg: 'row', xs: 'column' }}
      flexGrow={1}
      flexWrap={{ lg: false, xs: true }}
      justifyContent='space-between'
      width='100%'
    >
      <Stack
        alignItems='center'
        direction='column'
        height={height}
        justifyContent='center'
        minWidth='40%'
        width={isUpLg ? '60%' : '100%'}
      >
        {data && !noData && !isLoading ? (
          <ResponsivePie
            activeOuterRadiusOffset={6}
            animate
            arcLabel={(d) => (d?.value > 0 ? d?.data?.percent?.toString() : '')}
            arcLabelsTextColor='#fff'
            colors={{ datum: 'data.color' }}
            cornerRadius={3}
            data={data || []}
            endAngle={isCumulative ? 360 : 90}
            fit
            innerRadius={0.4}
            isInteractive
            layers={['arcs', 'arcLabels', centeredMetric]}
            margin={{ bottom: 20, left: 10, right: 10, top: 20 }}
            startAngle={isCumulative ? 0 : -90}
            theme={{
              fontFamily: 'Inter, sans-serif',
              text: { fontSize: 18 },
              tooltip: { container: { background: '#333' } },
            }}
            tooltip={(item) => (
              <ChartTooltip obj={item?.datum} showCount textKey='label' />
            )}
          />
        ) : (
          <Stack
            alignItems='center'
            direction='column'
            justifyContent='center'
            maxWidth={350}
          >
            {noData ? (
              <CircularProgressWithLabel
                isPlaceholder
                label='No data'
                size={height}
                thickness={10}
              />
            ) : (
              <Skeleton height={height} variant='circular' width={height} />
            )}
          </Stack>
        )}
      </Stack>
      <Stack
        alignItems='center'
        columnGap={isCumulative ? 4 : 8}
        direction={isCumulative ? 'column' : { xl: 'row', xs: 'column' }}
        flexGrow={1}
        justifyContent='flex-start'
        rowGap={4}
      >
        <Stack
          alignItems='center'
          direction='row'
          gap={4}
          justifyContent='center'
          minWidth={309}
        >
          {data?.map(({ color, colorName, Icon, id, label, percent }) => (
            <Stack
              alignItems='center'
              direction='column'
              gap={0.5}
              justifyContent='center'
              key={`${id}-${label}`}
            >
              <Box height={27}>
                {percent?.match(/\d+%/g) && !isLoading ? (
                  <Typography fontWeight={600} variant='h4'>
                    {percent}
                  </Typography>
                ) : isLoading ? (
                  <Skeleton height={27} variant='text' width={37} />
                ) : (
                  <Typography color='text.secondary' variant='overline'>
                    No data
                  </Typography>
                )}
              </Box>
              <Icon color={color} size={48} />
              <Typography
                color={colorName}
                fontWeight={500}
                sx={{ color: theme.palette[colorName].dark }}
                variant='body2'
              >
                {label}
              </Typography>
            </Stack>
          ))}
        </Stack>
        <Box minHeight={87} textAlign='center'>
          {isNaN(npsScore) === false &&
          data.map((d) => d.value).some((p) => p > 0) ? (
            <>
              <Typography mb={1.5} variant='h4'>
                Your NPS is {npsScore}
              </Typography>
              {npsScoreDescription?.secondary ? (
                <Typography color='grey.700' maxWidth={500} variant='body1'>
                  {npsScoreDescription?.secondary}
                </Typography>
              ) : null}
            </>
          ) : isLoading ? (
            <>
              <Skeleton
                height={27}
                sx={{ mb: 1.5 }}
                variant='text'
                width={309}
              />
              <Skeleton height={48} variant='text' width={309} />
            </>
          ) : null}
        </Box>
      </Stack>
    </Stack>
  );
};

NpsChart.propTypes = {
  events: PropTypes.array,
  height: PropTypes.number,
  isCumulative: PropTypes.bool,
  isFiltering: PropTypes.bool,
  isSingleEvent: PropTypes.bool,
  noEvents: PropTypes.bool,
};

export default NpsChart;
