import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Circle } from '@phosphor-icons/react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import FtnDataGrid from 'components/shared/dataGrid/FtnDataGrid';
import TextWithIcon from 'components/shared/TextWithIcon';
import { AuthContext } from 'contexts/AuthContext';
import { NpsAccent } from 'utils/nps';

const EventTable = ({ events, isLoading }) => {
  const {
    userProvider: { user },
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleSelectionChange = (params) => {
    const selectedEvent = events?.find((evt) => evt.id === params.id);
    if (user?.is_admin || selectedEvent.host_ids?.includes(user?.employee_id)) {
      navigate(`/event/${params.id}/analytics`);
    }
  };

  const columns = [
    {
      field: 'name',
      flex: 2,
      headerName: 'Event name',
      minWidth: 200,
    },
    {
      align: 'right',
      field: 'invitees',
      flex: 1,
      headerAlign: 'right',
      headerName: 'Invited',
      minWidth: 80,
      type: 'number',
    },
    {
      align: 'right',
      field: 'nps',
      flex: 1,
      headerAlign: 'right',
      headerName: 'NPS',
      minWidth: 80,
      renderCell: (params) => (
        <>
          {params.value !== null ? (
            <Stack direction='row' justifyContent='space-between'>
              <TextWithIcon
                Icon={Circle}
                iconProps={{ weight: 'fill' }}
                iconWrapperProps={{
                  sx: {
                    color: theme.palette[NpsAccent(params.value)]?.main,
                    mr: 1,
                    textAlign: 'right',
                  },
                }}
                primary={params.value}
                primaryTypographyProps={{ variant: 'body1' }}
              />
            </Stack>
          ) : (
            <Typography color='text.secondary' variant='overline'>
              &mdash;&nbsp;
            </Typography>
          )}
        </>
      ),
      type: 'number',
    },
    {
      align: 'right',
      field: 'rsvps',
      flex: 1,
      headerAlign: 'right',
      headerName: 'Accepted',
      minWidth: 80,
      type: 'number',
    },
    {
      align: 'right',
      field: 'attendees',
      flex: 1,
      headerAlign: 'right',
      headerName: 'Attended',
      minWidth: 80,
      type: 'number',
    },
    {
      align: isSmallScreen ? 'left' : 'right',
      field: 'date',
      flex: 2,
      headerAlign: isSmallScreen ? 'left' : 'right',
      headerName: 'Event date',
      minWidth: 180,
      type: 'dateTime',
      valueFormatter: ({ value }) =>
        value && dayjs(value).format(isSmallScreen ? 'MM/DD/YY LT' : 'LLL'),
      valueGetter: ({ value }) => value && new Date(value),
    },
  ];

  const data = useMemo(
    () =>
      events?.map((ev) => ({
        attendees: ev.attended_count,
        date: ev.start,
        has_nps: ev.survey_responses_count > 0,
        host_ids: ev.host_ids,
        id: ev.id,
        invitees: ev.invited_count,
        isClickable: user?.is_admin || ev.host_ids.includes(user?.employee_id),
        name: ev.name,
        nps:
          typeof ev.nps === 'number' && ev.nps !== 0
            ? ev.nps
            : ev.nps === 0 &&
              ev.survey_responses_count > 0 &&
              ev.nps_scores?.length > 0
            ? 0
            : null,
        responses: ev.survey_responses_count,
        rsvps: ev.rsvp_count,
      })),
    [events, user]
  );

  return (
    <FtnDataGrid
      columns={columns}
      data-testid='analytics-events-grid'
      isLoading={isLoading}
      isRowSelectable={(row) =>
        user?.is_admin || row?.host_ids?.includes(user?.employee_id)
      }
      noun='event'
      onRowClick={handleSelectionChange}
      rows={data}
      withLinearLoading
    />
  );
};

EventTable.propTypes = {
  events: PropTypes.shape({
    find: PropTypes.func,
    map: PropTypes.func,
  }),
  isLoading: PropTypes.any,
};

export default EventTable;
