import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Button,
  Divider,
  Grid,
  Link as MUILink,
  TextField,
  Typography,
} from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import MicrosoftLogin from 'components/Onboard/MicrosoftLogin';
import OktaLoginButton from 'components/Onboard/OktaLoginButton';
import OnboardFrame from 'components/Onboard/OnboardFrame';
import PasswordTextField from 'components/shared/PasswordTextField';
import { AuthContext } from 'contexts/AuthContext';
import { PublicOrgContext } from 'contexts/PublicOrgContext';
import { GoogleSSOScopes } from 'utils/google';
import { useExternalSnackbarProps } from 'utils/snackbar';
import { ValidateEmail } from 'utils/utils';

const StandardLogin = () => {
  const {
    token: { setToken },
    userProvider: { setUser },
  } = useContext(AuthContext);
  const [org, isPublicOrgLoading] = useContext(PublicOrgContext);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const snackbarProps = useExternalSnackbarProps();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isMfaRequired, setIsMfaRequired] = useState(false);
  const [oneTimePassword, setOneTimePassword] = useState(null);
  const [oneTimeUser, setOneTimeUser] = useState(null);

  const showSnackbar = (text, variant) => {
    enqueueSnackbar(text, { ...snackbarProps, variant });
  };

  const validEmail = useMemo(() => ValidateEmail(username), [username]);

  const prevLocation = sessionStorage.getItem('fromPageUrl');

  const handleAfterLoginSuccess = () => {
    if (prevLocation) {
      const forwardUrl = JSON.parse(prevLocation);
      const path = forwardUrl.split(window.location.host)[1];
      navigate(path);
      sessionStorage.removeItem('fromPageUrl');
    } else {
      navigate('/events');
    }
  };

  const resendVerification = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(`${import.meta.env.VITE_BE_URL}/api/users/resend_verification`, {
        email: username,
      })
      .then(() => {
        setIsLoading(false);
        enqueueSnackbar('Verification email re-sent — check your inbox!', {
          variant: 'success',
        });
      })
      .catch(() => {
        setIsLoading(false);
        enqueueSnackbar(
          "Unable to re-send verification email. Please ensure you've entered a valid email address!",
          { variant: 'error' }
        );
      });
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const confirmation_token = query.get('confirmation_token');

    if (confirmation_token) {
      setIsLoading(true);
      axios
        .post(`${import.meta.env.VITE_BE_URL}/api/users/verified`, {
          confirmation_token,
        })
        .then(() => {
          setIsLoading(false);
          showSnackbar(
            'Your account has now been verified! Please sign in.',
            'success'
          );
          navigate('/login');
        })
        .catch(() => {
          setIsLoading(false);
          showSnackbar('Account not verified. Please try again.', 'error');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const doLogin = (e) => {
    e.preventDefault();
    if (!validEmail) {
      return showSnackbar('You must enter a valid email address', 'error');
    } else {
      setIsLoading(true);
      axios
        .post(`${import.meta.env.VITE_BE_URL}/api/users/login`, {
          user: { email: username, organization_id: org.id, password },
        })
        .then((res) => {
          if (setToken) {
            setIsLoading(false);
            if (res.data?.mfa_required) {
              setIsMfaRequired(true);
              setOneTimeUser(res.data.user_id);
            } else {
              setUser(res.data.user);
              setToken(res.data.token);
              handleAfterLoginSuccess();
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
          const error = err?.response?.data?.error;
          if (error === 'Unauthorized access') {
            showSnackbar(
              "You are not authorized to access this organization's platform",
              'error'
            );
          } else {
            showSnackbar(error, 'error');
          }
        });
    }
  };

  const doMfa = () => {
    setIsLoading(true);
    axios
      .post(`${import.meta.env.VITE_BE_URL}/api/users/mfa_login`, {
        code: oneTimePassword,
        user_id: oneTimeUser,
      })
      .then((res) => {
        setIsLoading(false);
        setUser(res.data.user);
        setToken(res.data.token);
        handleAfterLoginSuccess();
      })
      .catch(() => {
        setIsLoading(false);
        showSnackbar('Invalid one-time password! Please try again.', 'error');
      });
  };

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    include_granted_scopes: false,
    onFailure: (response) => {
      if (response['error'] !== 'idpiframe_initialization_failed') {
        showSnackbar('Something went wrong!', 'error');
      } else {
        showSnackbar(
          'Cookies must be enabled to login with Google. Please enable cookies or use a different login option.',
          'error'
        );
      }
    },
    onSuccess: (response) => {
      setIsLoading(true);
      axios
        .post(`${import.meta.env.VITE_BE_URL}/api/users/google_oauth`, {
          user: {
            code: response.code,
            organization_id: org.id,
            redirect_uri: window.location.origin,
          },
        })
        .then((res) => {
          if (setToken) {
            setIsLoading(false);
            if (res.data?.mfa_required) {
              setIsMfaRequired(true);
              setOneTimeUser(res.data.user_id);
            } else {
              setUser(res.data.user);
              setToken(res.data.token);
              handleAfterLoginSuccess();
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    },
    prompt: 'select_account',
    scope: GoogleSSOScopes,
  });

  const mustSignInFromOkta = org?.force_okta_sign_in;

  return (
    <OnboardFrame
      conditions={[isPublicOrgLoading]}
      data-testid='sign-in-container'
      subtitle={
        isMfaRequired
          ? null
          : mustSignInFromOkta
          ? 'You need to log in from within Okta'
          : 'Please enter your details below to sign in.'
      }
      title={
        isMfaRequired
          ? 'One-time password'
          : `Welcome${mustSignInFromOkta ? '' : ' back'}`
      }
    >
      {!mustSignInFromOkta ? (
        <>
          {isMfaRequired ? (
            <form
              data-testid='mfa-container'
              onSubmit={() =>
                oneTimePassword?.length > 0
                  ? doMfa()
                  : showSnackbar(
                      'Please enter your one-time password to sign in.',
                      'error'
                    )
              }
            >
              <TextField
                field='passcode'
                fullWidth
                id='passcode'
                label='Enter the one-time password sent to your email'
                onChange={(e) => setOneTimePassword(e.target.value)}
                placeholder='Enter your code here.'
                value={oneTimePassword}
              />
              <LoadingButton
                color='primary'
                data-testid='sign-in'
                loading={isLoading}
                onClick={doMfa}
                variant='contained'
              >
                Sign in
              </LoadingButton>
            </form>
          ) : null}
          <Grid container direction='column' spacing={1}>
            <Grid item xs={12}>
              <form
                data-testid='sign-in-form'
                onSubmit={doLogin}
                style={{ maxWidth: '100%' }}
              >
                <Grid columnSpacing={2} container direction='column' mb={1}>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete='email'
                      fullWidth
                      id='sign-in-email'
                      label='Email'
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder='you@yourcompany.com'
                      required
                      type='email'
                      value={username}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PasswordTextField
                      handleChange={(e) => setPassword(e.target.value)}
                    />
                    <MUILink
                      component={RouterLink}
                      data-testid='forgot-password?'
                      display='block'
                      textAlign='right'
                      to='/reset-password'
                      underline='none'
                      variant='overline'
                    >
                      Forgot password?
                    </MUILink>
                  </Grid>
                  <Grid item sx={{ mt: 4 }} xs={12}>
                    <LoadingButton
                      color='secondary'
                      data-testid='sign-in'
                      fullWidth
                      loading={isLoading}
                      type='submit'
                      variant='contained'
                    >
                      Sign in
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            {org && (org.calendar_enabled !== 'none' || org.okta_enabled) && (
              <Grid item sx={{ mb: 2, mt: 1 }} xs={12}>
                <Divider>
                  <Typography color='text.secondary' variant='body1'>
                    or
                  </Typography>
                </Divider>
              </Grid>
            )}
            {org?.okta_enabled && org?.okta_type === 'oidc' ? (
              <Grid data-testid='okta-login' item xs={12}>
                <OktaLoginButton />
              </Grid>
            ) : org?.calendar_enabled === 'google' ? (
              <Grid data-testid='google-login' item xs={12}>
                <LoadingButton
                  data-testid='sign-in-with-google'
                  fullWidth
                  loading={isLoading}
                  onClick={() => googleLogin()}
                  startIcon={
                    <Avatar
                      alt='Google Logo'
                      data-testid='google-logo'
                      src='/images/google-logo.svg'
                      sx={{ height: 24, width: 24 }}
                    />
                  }
                  variant='bordered'
                >
                  Sign in with Google
                </LoadingButton>
              </Grid>
            ) : (
              org?.calendar_enabled === 'outlook' && (
                <Grid data-testid='microsoft-login' item xs={12}>
                  <MicrosoftLogin
                    onMfaRequired={(user_id) => {
                      setIsMfaRequired(true);
                      setOneTimeUser(user_id);
                    }}
                    onSuccess={(token, user) => {
                      setToken(token);
                      setUser(user);
                      handleAfterLoginSuccess();
                    }}
                    org={org}
                  />
                </Grid>
              )
            )}

            <Grid
              data-testid='subtext'
              item
              sx={{ textAlign: 'center' }}
              xs={12}
            >
              <Typography mt={4} variant='body1'>
                Don&apos;t have an account yet?{' '}
                <MUILink
                  component={RouterLink}
                  data-testid='sign-up'
                  to='/sign-up'
                >
                  Sign up
                </MUILink>
              </Typography>
              <Button
                data-testid='resend-confirmation-email'
                onClick={(e) => resendVerification(e)}
                sx={{ mt: 2 }}
                variant='text'
              >
                Resend confirmation email
              </Button>
            </Grid>
          </Grid>
        </>
      ) : null}
    </OnboardFrame>
  );
};
export default StandardLogin;
