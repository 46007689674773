import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { Icon } from '@phosphor-icons/react';

import { flexRow } from 'utils/styles';

type OptionValue = string | number;

interface Option {
  colorName?: string;
  Icon: Icon;
  id: OptionValue;
  name: string;
}

interface EventGuestsSelectProps {
  handleChange: (value: string) => void;
  id?: string | number;
  label: string;
  options: Option[];
  value: OptionValue;
}

const EventGuestsSelect: FC<EventGuestsSelectProps> = ({
  handleChange,
  id: idPrefix,
  label,
  options,
  value = '',
}) => {
  const [selectedValue, setSelectedValue] = useState(value || '');

  useEffect(() => {
    if (value && selectedValue !== value) {
      setSelectedValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const selectId = useMemo(
    () => `${idPrefix}${label ? label?.toLowerCase().replace(' ', '-') : ''}`,
    [idPrefix, label]
  );

  return options?.length > 0 ? (
    <Select
      data-test-id={selectId}
      displayEmpty
      fullWidth
      id={selectId}
      inputProps={{ 'aria-label': label }}
      onChange={(e: SelectChangeEvent<any>) => {
        const newValue = e.target.value;
        setSelectedValue(newValue);
        handleChange(newValue);
      }}
      size='small'
      sx={{
        '.MuiSelect-select': {
          ...flexRow,
          alignItems: 'center',
          flexWrap: 'nowrap',
        },
        ...(selectedValue === '' ? { color: 'grey.400' } : {}),
      }}
      value={selectedValue}
    >
      <MenuItem sx={{ color: 'grey.500' }} value=''>
        Select one
      </MenuItem>
      {options
        ?.filter((option) => option?.id && option?.id !== '')
        ?.map(({ colorName, Icon, id: optId, name }) => (
          <MenuItem
            key={`${selectId}-${optId}-option`}
            sx={{
              ...flexRow,
              alignItems: 'center',
              flexWrap: 'nowrap',
              m: 0,
            }}
            value={optId}
          >
            <ListItemIcon
              sx={{
                '&.MuiListItemIcon-root': {
                  color: `${colorName}.main`,
                  height: 20,
                  minWidth: 28,
                  width: 20,
                },
              }}
            >
              <Icon size={20} />
            </ListItemIcon>
            <ListItemText
              primary={name}
              primaryTypographyProps={{
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              sx={{ m: 0 }}
            />
          </MenuItem>
        ))}
    </Select>
  ) : (
    ''
  );
};
export default EventGuestsSelect;
