import { FtnUser } from 'types/FtnUser';
import { fullName } from 'utils/employee';

export const searchFilter = (searchVal: string, cal: any, nameKey = 'name') => {
  const lowSearch = searchVal.toLowerCase();
  return searchVal.trim().length === 0
    ? true
    : cal[nameKey]?.toLowerCase().includes(lowSearch);
};

export const getPersonalCalendar = (user: FtnUser, is_deleted = false) => {
  const key = 'personal';
  const displayName = `${fullName(user)} (Personal)`;
  if (user?.has_google_calendar_auth || user?.has_outlook_auth) {
    return {
      calendar_id: key,
      id: user?.email || key,
      is_deleted,
      is_personal: true,
      label: displayName,
      name: displayName,
      value: key,
    };
  }
  return null;
};
