import React, { ReactNode, useContext } from 'react';
import {
  AppBar,
  Box,
  IconButton,
  SxProps,
  Toolbar,
  Typography,
  TypographyProps,
} from '@mui/material';
import { Stack } from '@mui/system';
import { List } from '@phosphor-icons/react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import CalendarBanner from 'components/Core/CalendarBanner';
import ContentWidthContainer from 'components/shared/layout/ContentWidthContainer';
import { LayoutContext } from 'contexts/LayoutContext';

export interface DefaultAppBarProps {
  appBarStyles?: SxProps;
  button?: ReactNode;
  fixedContent?: ReactNode;
  fixedLocation?: 'with-title' | 'below-title' | 'below-tabs';
  fixedSpacing?: number;
  header?: ReactNode;
  noPadding?: boolean;
  TabBar?: ReactNode;
  title?: ReactNode;
  titleToolbarVariant?: 'dense' | 'regular';
  titleTypographyProps?: TypographyProps;
}

const DefaultAppBar: React.FC<DefaultAppBarProps> = ({
  appBarStyles = {},
  button = null,
  fixedContent = null,
  fixedLocation = 'below-tabs',
  fixedSpacing = 0,
  header = null,
  noPadding = false,
  TabBar = null,
  title,
  titleToolbarVariant = 'regular',
  titleTypographyProps,
}) => {
  const { headerRef, isMobile, toggleSidenav } = useContext(LayoutContext);

  const fixedContentToolbar = (props: any = {}) => (
    <Toolbar disableGutters {...props} data-testid='fixed-content-toolbar'>
      <Stack
        alignItems={isMobile ? 'flex-start' : 'center'}
        direction={isMobile ? 'column' : 'row'}
        gap={fixedSpacing}
        justifyContent={isMobile ? 'flex-start' : 'space-between'}
        width='100%'
      >
        {fixedContent}
      </Stack>
    </Toolbar>
  );

  return (
    <>
      <GoogleOAuthProvider
        clientId={import.meta.env.VITE_GOOGLE_CALENDAR_CLIENT_ID}
      >
        <CalendarBanner />
      </GoogleOAuthProvider>
      <AppBar
        elevation={3}
        position='sticky'
        ref={headerRef}
        sx={{
          ...(noPadding ? {} : { pb: 2, pt: 1.5, px: 0 }),
          ...(appBarStyles || {}),
        }}
      >
        <ContentWidthContainer>
          <Toolbar
            data-testid='title-toolbar'
            disableGutters
            sx={{
              alignItems: 'center',
              flexDirection: 'row',
              flexWrap: isMobile ? 'wrap' : 'nowrap',
              gap: 2,
              justifyContent: 'space-between',
              maxWidth: '100%',
              width: '100%',
            }}
            variant={titleToolbarVariant}
          >
            {header || (
              <Stack
                alignItems='start'
                direction='row'
                flexWrap={isMobile ? 'wrap' : 'nowrap'}
                justifyContent='space-between'
                width='100%'
              >
                <Stack alignItems='center' direction='row' gap={2}>
                  {isMobile && !location.pathname.includes('public') && (
                    <IconButton
                      data-testid='mobile-sidenav-toggle'
                      onClick={() => toggleSidenav()}
                    >
                      <List />
                    </IconButton>
                  )}
                  <Typography
                    color='grey.900'
                    component='div'
                    sx={
                      titleTypographyProps?.sx || fixedLocation === 'with-title'
                        ? { mr: 2 }
                        : {}
                    }
                    variant={titleTypographyProps?.variant || 'h1'}
                  >
                    {title}
                  </Typography>
                  {fixedContent && fixedLocation === 'with-title' ? (
                    <>
                      {fixedContent}
                      <Box sx={{ flex: '1 0 auto' }}>&nbsp;</Box>
                    </>
                  ) : null}
                </Stack>
                {button}
              </Stack>
            )}
          </Toolbar>
          {fixedContent && fixedLocation === 'below-title'
            ? fixedContentToolbar()
            : null}
          {TabBar}
          {fixedContent && fixedLocation === 'below-tabs'
            ? fixedContentToolbar({
                sx: { minHeight: 'unset !important', mt: 3 },
              })
            : null}
        </ContentWidthContainer>
      </AppBar>
    </>
  );
};

export default DefaultAppBar;
