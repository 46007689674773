import React from 'react';
import { ListItem, Stack } from '@mui/material';
import { Icon } from '@phosphor-icons/react';
import Grid from '@mui/material/Grid2';
import TextWithIcon from 'components/shared/TextWithIcon';

interface FormSectionProps {
  description?: string;
  fields: React.JSX.Element;
  Icon: Icon;
  title: string;
}

const FormSection = ({
  description,
  fields,
  Icon,
  title,
}: FormSectionProps) => {
  return (
    <Grid
      container
      flexDirection="row"
      justifyContent="stretch"
      spacing={5}
      width="100%"
    >
      <Grid size={{ xs: 12, sm: 12, md: 4, lg: 5 }}>
        <ListItem
          disableGutters
          disablePadding
          sx={{
            alignItems: 'flex-start',
            gap: 1,
            justifyContent: 'flex-start',
          }}
        >
          <TextWithIcon Icon={Icon} primary={title} secondary={description} />
        </ListItem>
      </Grid>
      <Grid
        size={{ xs: 12, sm: 12, md: 8, lg: 7 }}
        sx={{
          '& .MuiFormControl-root': {
            m: 0,
          },
        }}
      >
        <Stack direction="column" gap={2} width="100%">
          {fields}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default FormSection;
