import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ConditionalLoader } from 'components/Core/Loader';
import ShortAnswer from 'components/Core/Questions/ShortAnswer';
import EventPreviewCard from 'components/Event/EventPreviewCard';
import EventPreviewHeader from 'components/Event/EventPreviewHeader';
import { rsvpOptions } from 'components/Events/Controls/Rsvp/Rsvp.constants';
import RsvpCardHeader from 'components/Events/Controls/Rsvp/RsvpCardHeader';
import RsvpPauseStatus from 'components/Events/Controls/Rsvp/RsvpPauseStatus';
import DefaultLayout from 'components/shared/layout/DefaultLayout';
import { EventDetailsFormWatch } from 'types/EventDetailsForm';

interface EventPreviewDialogContentProps {
  isPublished: boolean;
  rsvpPauseActive?: boolean;
  watch: EventDetailsFormWatch;
}

const PreviewFields = [
  {
    autocomplete: 'given-name',
    id: 'first_name',
    noun: 'your first name',
    placeholder: 'Riley',
    position: -3,
    question_type: 'short',
    required: true,
    title: 'First name',
  },
  {
    autocomplete: 'family-name',
    id: 'last_name',
    noun: 'your last name',
    placeholder: 'Adams',
    position: -2,
    question_type: 'short',
    required: true,
    title: 'Last name',
  },
  {
    autocomplete: 'email',
    id: 'email',
    noun: 'your email',
    placeholder: 'riley.adams@mycompany.com',
    position: -1,
    question_type: 'short',
    required: true,
    title: 'Email address',
  },
];

const EventPreviewDialogContent = ({
  isPublished,
  rsvpPauseActive = false,
  watch,
}: EventPreviewDialogContentProps) => (
  <DefaultLayout
    appBarStyles={undefined}
    contentPaddingBottom={0}
    contentPaddingTop={0}
    title={<EventPreviewHeader isPublished={isPublished} watch={watch} />}
    withoutContainer
  >
    <ConditionalLoader conditions={[]}>
      <Stack
        alignItems='stretch'
        data-testid='event-view-preview'
        direction='column'
      >
        <Grid alignItems='flex-start' container direction='row' spacing={3}>
          <Grid size={{ xs: 12, md: 7 }}>
            <EventPreviewCard watch={watch} />
          </Grid>

          <Grid alignSelf='start' size={{ xs: 12, md: 5 }} position='sticky' top='1px'>
            <Stack direction='column' gap={3}>
              {rsvpPauseActive && <RsvpPauseStatus />}
              <Card data-testid='pre-rsvp-view' elevation={2}>
                <RsvpCardHeader title='Will you attend this event?' />
                <CardContent>
                  <Stack alignItems='stretch' direction='column' gap={1} p={0}>
                    {PreviewFields.map((field) => (
                      <ShortAnswer
                        key={field.id}
                        noOrder
                        question={field}
                        readonly
                      />
                    ))}
                  </Stack>
                </CardContent>
                <CardActions>
                  <ToggleButtonGroup
                    aria-label='event rsvp'
                    color='neutral'
                    exclusive
                    fullWidth
                    onChange={() => undefined}
                    sx={{
                      flexDirection: {
                        md: 'columns',
                        sm: 'row',
                        xs: 'column',
                      },
                    }}
                  >
                    {rsvpOptions.map((opt) => (
                      <ToggleButton
                        aria-label={opt.buttonOutcome}
                        key={opt.id}
                        value={opt.id}
                      >
                        {opt.buttonLabel}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </CardActions>
              </Card>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </ConditionalLoader>
  </DefaultLayout>
);

export default EventPreviewDialogContent;
