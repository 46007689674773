import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useFlag } from '@unleash/proxy-client-react';
import fileDownload from 'js-file-download';
import * as _ from 'lodash';
import { clone, keyBy } from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import sanitize from 'sanitize-filename';

import Head from 'components/Core/Head';
import { FullscreenLoader } from 'components/Core/Loader';
import {
  getRsvpStatusDetails,
  rsvpOptions,
  rsvpSnackbarMessageGeneric,
} from 'components/Events/Controls/Rsvp/Rsvp.constants';
import {
  DetailsValidators,
  SurveysValidators,
  ValidateEvent,
} from 'components/Events/Editor/validators';
import {
  useCloneEvent,
  useDeleteCommunicationHistory,
  useDeleteEvent,
  useDeleteSurvey,
  useHandleRSVP,
  useRemoveCalendar,
  useRemoveZoomMeeting,
  useSaveEvent,
  useSaveEventWithModal,
  useSaveGoogleAuth,
  useSaveMicrosoftAuth,
  useSaveSurvey,
  useSendInvitationOrMessageNow,
} from 'components/Events/Hooks/Actions';
import {
  useDateTimeErrors,
  useLoadEvent,
  usePendingEvent,
  usePendingSurveys,
  useReloadEvent,
  useSurveyResponses,
  useUnsavedMessageChanges,
  useUnsavedSurveyChanges,
} from 'components/Events/Hooks/Event';
import {
  useFormValidation,
  useImageError,
  useNavigateFromGroup,
  usePendingData,
} from 'components/Events/Hooks/Generic';
import useCheckCommsTiming from 'components/Events/Hooks/useCheckCommsTiming';
import { AuthContext } from 'contexts/AuthContext';
import useDisplayedHostSelections from 'hooks/useDisplayedHostSelections';
import { useEventLocationType } from 'hooks/useEventLocationType';
import { axiosAuthenticated as axios } from 'utils/axios';
import {
  DefaultInviteMessage,
  DefaultInvites,
  DefaultManagerSurvey,
  DefaultMessage,
  hasStarted,
  isOver,
} from 'utils/event';
import { DefaultSurveyType } from 'utils/survey';
import { transformEvent } from 'utils/transform';
import { useDelayedLoading } from 'utils/utils';

export const ManagerContext = createContext();

const ManageTabsCommunications = [
  {
    key: 'edit',
    label: 'Details',
    message: 'Weʼre updating your event details!',
    slug: 'details',
    validators: DetailsValidators,
  },
  {
    key: 'surveys',
    label: 'Surveys',
    message: 'Weʼre updating your survey!',
    slug: 'surveys',
    validators: SurveysValidators,
  },
  {
    key: 'communications',
    label: 'Communications',
    message: 'Weʼre updating your communications!',
    slug: 'communications',
  },
  {
    key: 'guests',
    label: 'Guests',
    message: 'Weʼre updating your guest list!',
    slug: 'guests',
  },
  {
    key: 'budget',
    label: 'Budget',
    message: 'Weʼre updating your budget!',
    slug: 'budget',
  },
  {
    key: 'analytics',
    label: 'Analytics',
    message: 'Weʼre collecting your analytics!',
    slug: 'analytics',
  },
];

export const ManagerProvider = ({ children }) => {
  const {
    userProvider: { user },
  } = useContext(AuthContext);

  const { endTask, isWorking, longWorking, startTask } = useDelayedLoading();
  const navigate = useNavigate();
  const { addImageError, clearImageError, imageErrors } = useImageError();
  const location = useLocation();
  const { applyMulti, applySingle, clearPending, pendingData } = usePendingData(
    {
      invites: DefaultInvites(user),
      message: DefaultMessage,
      surveys: DefaultManagerSurvey,
    }
  );
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const isBudgetFlagEnabled = useFlag('event-budget');

  const isLoading = useMemo(
    () => isWorking || longWorking,
    [isWorking, longWorking]
  );

  const Tabs = useMemo(() => {
    const tabsArray = clone(ManageTabsCommunications);

    const finalTabs = tabsArray.filter(
      (tab) => isBudgetFlagEnabled || tab.key !== 'budget'
    ); // Remove budget tab if not enabled
    return keyBy(finalTabs, 'key');
  }, [isBudgetFlagEnabled]);

  const [activeInvitationMethod, setActiveInvitationMethod] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [currentPath, setCurrentPath] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [endTimeError, setEndTimeError] = useState('');
  const [isAddingInvitations, setIsAddingInvitations] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isSendingCommunication, setIsSendingCommunication] = useState(false);
  const [isSurveyEmailWorking, setIsSurveyEmailWorking] = useState(false);
  const [openInvitationMethod, setOpenInvitationMethod] = useState(null);
  const [previousPath, setPreviousPath] = useState('');
  const [rsvpStatus, setRsvpStatus] = useState(null);
  const [rsvpStep, setRsvpStep] = useState();
  const [savedEvent, setSavedEvent] = useState(null);
  const [showErrors, setShowErrors] = useState(false);
  const [showPreEventSurvey, setShowPreEventSurvey] = useState(false);
  const [startDateError, setStartDateError] = useState('');
  const [startTimeError, setStartTimeError] = useState('');
  const [surveyErrors, setSurveyErrors] = useState({});
  const [surveyFieldIdsToDisplayErrorsOn, setSurveyFieldIdsToDisplayErrorsOn] =
    useState([]);
  const [surveyJustSubmitted, setSurveyJustSubmitted] = useState(false);

  const surveyType = useMemo(
    () =>
      pendingData.surveyType
        ? pendingData.surveyType
        : (pendingData.surveyType = DefaultSurveyType(savedEvent)),
    [pendingData, savedEvent]
  );

  const inviteMessage = useMemo(
    () =>
      pendingData.invite_message
        ? pendingData.invite_message
        : (pendingData.invite_message = DefaultInviteMessage()),
    [pendingData]
  );

  const communicationMessage = useMemo(
    () =>
      pendingData.message
        ? pendingData.message
        : (pendingData.message = DefaultMessage),
    [pendingData]
  );

  const isPublic = useMemo(
    () => ['open', 'organization'].includes(savedEvent?.privacy),
    [savedEvent]
  );

  const canEdit = useMemo(
    () => user?.is_admin || savedEvent?.host_ids?.includes(user?.employee_id),
    [user, savedEvent]
  );

  useLoadEvent(
    startTask,
    endTask,
    () => !savedEvent && !isWorking,
    setSavedEvent,
    clearPending
  );

  const { reloadEvent } = useReloadEvent(
    startTask,
    endTask,
    setSavedEvent,
    clearPending
  );

  const pendingSurveys = usePendingSurveys(savedEvent, pendingData);

  const surveyResponses = useSurveyResponses(pendingData, pendingSurveys);

  const groupId = useNavigateFromGroup(location, savedEvent?.group_ids);

  const {
    dismissAlert: dismissRescheduleCommsAlert,
    hasDismissedAlert: hasDismissedRescheduleCommsAlert,
    hasInvalidComms,
    isAlertVisible: isRescheduleCommsAlertVisible,
  } = useCheckCommsTiming(savedEvent);

  const clearPendingSurvey = useCallback(
    (type) => {
      setShowErrors(false);
      clearPending(false, true, false, false, type);
    },
    [clearPending]
  );

  const updateGuestList = useCallback(
    (invitees, action, onDone, column) => {
      const id = parseInt(location?.pathname?.split('/')[2]);
      const inviteesToUpdate = _.map(invitees, (invitee) => {
        const updated = {
          id: invitee.id,
        };
        if (action === 'update') {
          switch (column) {
            case 'status':
              updated.status = invitee.status;
              break;
            case 'attended':
              if (invitee.attended === '') {
                updated.attended = 'not_recorded';
                updated.attendance_type = 'unknown';
              } else {
                if (invitee.attended === 'no') {
                  updated.attended = invitee.attended;
                  updated.attendance_type = 'unknown';
                }
                if (invitee.attended === 'yes') {
                  updated.attended = invitee.attended;
                  updated.attendance_type = 'unknown';
                }
              }
              break;
            case 'attendance_type':
              if (invitee.attendance_type === '') {
                updated.attendance_type = 'unknown';
              } else {
                updated.attendance_type = invitee.attendance_type;
                updated.attended = 'yes';
              }
              break;
            default:
              break;
          }
        }
        return updated;
      });

      axios(
        {
          data: { invitees: inviteesToUpdate },
          method: action === 'update' ? 'patch' : 'delete',
          url: `/api/events/${id}/invitees.json`,
        },
        (res) => {
          if (res.data && res.data.error) {
            onDone && onDone();
          } else {
            setSavedEvent(transformEvent(res.data));
            onDone && onDone();
          }
        }
      );
    },
    [location?.pathname]
  );

  const requestAnalyticsData = useCallback(
    (onDone) => {
      const id = parseInt(location?.pathname?.split('/')[2]);
      axios(
        { method: 'get', url: `/api/events/${id}/analytics` },
        (res) => {
          const updatedEvent = _.assign(
            {},
            savedEvent,
            transformEvent(res.data)
          );
          setSavedEvent(updatedEvent);
          onDone && onDone();
        },
        () => {
          enqueueSnackbar('Something went wrong getting analytics data.', {
            variant: 'error',
          });
          onDone && onDone();
        }
      );
    },
    [location, savedEvent, enqueueSnackbar]
  );

  const analyticsExport = useCallback(
    (name) => {
      const exportType = pendingData?.surveyType?.value;
      const id = parseInt(location?.pathname?.split('/')[2]);
      if (id && exportType) {
        if (exportType === 'post_event_survey') {
          axios(
            { url: `/api/events/${id}/export_post` },
            (r) => {
              fileDownload(r.data, sanitize(name + ' - Event Feedback.csv'));
            },
            () => {
              enqueueSnackbar(
                'Something went wrong! Export failed. Please try again',
                { variant: 'error' }
              );
            }
          );
        } else if (exportType === 'pre_event_survey') {
          axios(
            { url: `/api/events/${id}/export_pre` },
            (r) => {
              fileDownload(
                r.data,
                sanitize(name + ' - Pre Event Questions.csv')
              );
            },
            () => {
              enqueueSnackbar(
                'Something went wrong! Export failed. Please try again',
                { variant: 'error' }
              );
            }
          );
        }
      }
    },
    [enqueueSnackbar, location?.pathname, pendingData?.surveyType]
  );

  const sendSurvey = useCallback(
    (type, callback) => {
      const id = parseInt(location?.pathname?.split('/')[2]);

      let successMessage = 'Survey has been sent successfully.';

      if (type === 'pre_event_survey') {
        successMessage = 'Pre-event email surveys have been sent successfully.';
      } else if (type === 'post_event_survey') {
        successMessage =
          'Post-event email surveys have been sent successfully.';
      }

      if (id && !isSurveyEmailWorking) {
        setIsSurveyEmailWorking(true);
        axios(
          {
            method: 'get',
            url: `/api/events/${id}/send_survey`,
          },
          (res) => {
            if (res.data && res.data.success === true) {
              enqueueSnackbar(successMessage, {
                variant: 'success',
              });
            } else {
              enqueueSnackbar('Something went wrong! Please try again', {
                variant: 'error',
              });
            }
            setIsSurveyEmailWorking(false);
            if (callback) {
              callback();
            }
          },
          () => {
            enqueueSnackbar('Something went wrong! Please try again', {
              variant: 'error',
            });
            setIsSurveyEmailWorking(false);
            if (callback) {
              callback();
            }
          }
        );
      }
    },
    [enqueueSnackbar, isSurveyEmailWorking, location?.pathname]
  );

  const exportGuestList = useCallback(
    (name) => {
      const id = parseInt(location?.pathname?.split('/')[2]);
      if (id) {
        axios(
          { url: `/api/events/${id}/export_guest_list` },
          (r) => {
            fileDownload(r.data, sanitize(name + ' - Guest List.csv'));
            enqueueSnackbar('Guest List has been exported to a CSV!', {
              variant: 'success',
            });
          },
          () => {
            enqueueSnackbar(
              'Something went wrong! Export failed. Please try again',
              { variant: 'error' }
            );
          }
        );
      }
    },
    [enqueueSnackbar, location?.pathname]
  );

  const emailGuests = useCallback(
    (invitees, emailSubject, message, sendPreview = false) => {
      const id = parseInt(location?.pathname?.split('/')[2]);
      const email_params = {
        body: message,
        event_id: id,
        recipients: invitees,
        send_preview: sendPreview,
        subject: emailSubject,
      };
      if (id) {
        axios(
          {
            data: { email_params },
            method: 'post',
            url: '/api/invitees/send_email_reminder',
          },
          (res) => {
            if (res.data && res.data.success === true) {
              enqueueSnackbar(
                `${
                  sendPreview
                    ? 'A preview message has been sent to your email'
                    : 'A message has been sent to your guests.'
                }`,
                {
                  variant: 'success',
                }
              );
            }
          },
          () => {
            enqueueSnackbar('Something went wrong! Please try again', {
              variant: 'error',
            });
          }
        );
      }
    },
    [enqueueSnackbar, location?.pathname]
  );

  const pendingEvent = usePendingEvent(savedEvent, pendingData);

  const {
    active: activeLocationType,
    setManuallySelected: setActiveLocationType,
  } = useEventLocationType({ pendingEvent, savedEvent });

  const {
    allDisplayedHostOptions,
    allDisplayedHostSelections,
    employeeOptions,
  } = useDisplayedHostSelections(pendingEvent?.displayable_hosts);

  const _afterSaveSuccess = useCallback(
    (res) => {
      const message = res?.successMessage || 'Your event has been updated!';
      enqueueSnackbar(message, {
        variant: 'success',
      });
      setActiveLocationType(undefined);
      setSavedEvent(transformEvent(res.data));
      clearPending(
        true,
        true,
        pendingData?.cover_image && !res.data.cover_image,
        true,
        false,
        true
      );
    },
    [
      clearPending,
      enqueueSnackbar,
      pendingData?.cover_image,
      setActiveLocationType,
    ]
  );

  const _afterSaveFail = useCallback(
    (err) => {
      if (err?.response?.data?.errors) {
        enqueueSnackbar(
          _.chain(err.response.data.errors)
            .map((m, k) => `${k}: ${m}`)
            .join(', ')
            .value(),
          { variant: 'error' }
        );
      }
    },
    [enqueueSnackbar]
  );

  const _afterRSVPSaveFail = useCallback(
    (err) => {
      queryClient.invalidateQueries({
        queryKey: ['event'],
      });
      if (err.response.status === 403) {
        enqueueSnackbar(
          `You are not allowed to RSVP this event based on the email provided.`,
          {
            variant: 'error',
          }
        );
      } else {
        enqueueSnackbar(
          _.chain(err?.response?.data?.errors)
            .map((m, k) => `${k}: ${m}`)
            .join(', ')
            .value(),
          { variant: 'error' }
        );
      }
      setRsvpStatus(null);
    },
    [enqueueSnackbar, queryClient]
  );

  const _afterRsvpSuccess = useCallback(
    (res, action, surveySubmitted) => {
      queryClient.invalidateQueries({
        queryKey: ['event', res?.data?.id, 'invitee'],
      });
      let snackbarMessage = rsvpSnackbarMessageGeneric;
      let surveySubmissionOnly = false;
      if (rsvpStatus === action && surveySubmitted) {
        snackbarMessage = 'Thank you for completing the survey!';
        surveySubmissionOnly = true;
      }
      setRsvpStatus(action);
      if (action === undefined) {
        enqueueSnackbar(snackbarMessage, { variant: 'success' });
      } else {
        const message = rsvpOptions.find(
          (opt) => opt.id === action
        )?.snackbarMessage;
        enqueueSnackbar(surveySubmissionOnly ? snackbarMessage : message, {
          variant: 'success',
        });
        setSavedEvent(transformEvent(res.data));
        setRsvpStatus(action);
        if (
          savedEvent?.pre_event_survey?.survey_questions?.length > 0 &&
          !surveySubmitted &&
          action === 'rsvp'
        ) {
          setShowPreEventSurvey(true);
        }
        setSurveyJustSubmitted(surveySubmitted);
      }
      setRsvpStep('post-rsvp');
    },
    [
      enqueueSnackbar,
      queryClient,
      rsvpStatus,
      savedEvent?.pre_event_survey?.survey_questions?.length,
    ]
  );

  const handleRSVP = useHandleRSVP(
    startTask,
    endTask,
    savedEvent?.id,
    'manager',
    _afterRsvpSuccess,
    _afterRSVPSaveFail,
    surveyResponses
  );

  const handleRsvpClick = (type) => {
    const typeDetails = getRsvpStatusDetails(type);
    handleRSVP(typeDetails.id, false);
  };

  const saveEventActionDontSendUpdate = useSaveEvent(
    startTask,
    endTask,
    pendingData,
    savedEvent,
    _afterSaveSuccess,
    _afterSaveFail,
    false,
    false
  );

  const saveEventActionSendUpdate = useSaveEvent(
    startTask,
    endTask,
    pendingData,
    savedEvent,
    _afterSaveSuccess,
    _afterSaveFail,
    false,
    true
  );

  const { saveEvent, saveEventModal } = useSaveEventWithModal(
    saveEventActionDontSendUpdate,
    saveEventActionSendUpdate
  );

  const saveEventAction = () => {
    if (isOver(pendingEvent)) {
      saveEventActionDontSendUpdate();
    } else {
      saveEvent();
    }
  };

  const sendCommunication = useSendInvitationOrMessageNow(
    () => setIsSendingCommunication(true),
    () => setIsSendingCommunication(false),
    useCallback(
      (res, commType) => {
        const updatedEvent = transformEvent(res.data);
        setSavedEvent(updatedEvent);
        enqueueSnackbar(`Sending your ${commType} now!`, {
          variant: 'success',
        });
      },
      [enqueueSnackbar]
    ),
    _afterSaveFail
  );

  const saveRsvpPauseActive = useSaveEvent(
    startTask,
    endTask,
    pendingData,
    savedEvent,
    useCallback(
      (res) => {
        const updatedEvent = transformEvent(res.data);
        setSavedEvent(updatedEvent);
        enqueueSnackbar(
          `RSVPs for this event have been ${
            updatedEvent?.rsvp_pause_active ? 'closed' : 'opened'
          }. Turn RSVPs back ${
            updatedEvent?.rsvp_pause_active ? 'on' : 'off'
          } from the "Guests" tab.`,
          {
            variant: 'success',
          }
        );
        clearPending(true, true, false, true);
      },
      [clearPending, enqueueSnackbar]
    ),
    _afterSaveFail,
    false
  );

  const removeCalendar = useRemoveCalendar(
    startTask,
    endTask,
    _afterSaveSuccess,
    _afterSaveFail
  );

  const cloneEvent = useCloneEvent(
    startTask,
    endTask,
    (resp) => {
      enqueueSnackbar(
        `The event was duplicated! You can rename it, update it as needed, then publish it!`,
        { variant: 'success' }
      );
      navigate(`/event/${resp.id}/create`);
    },
    () => {
      enqueueSnackbar('Something went wrong, please try again.', {
        variant: 'error',
      });
    }
  );

  const deleteSurvey = useDeleteSurvey(
    startTask,
    endTask,
    pendingData,
    savedEvent,
    _afterSaveSuccess,
    _afterSaveFail
  );

  const { deleteModal, showDeleteModal } = useDeleteEvent(
    startTask,
    endTask,
    pendingEvent,
    () => {
      enqueueSnackbar(`Event deleted!`, {
        variant: 'success',
      });
      navigate(`/events`);
    },
    () => {
      enqueueSnackbar('Something went wrong, please try again', {
        variant: 'error',
      });
    }
  );

  const deleteCommunication = useDeleteCommunicationHistory(
    () => setIsSendingCommunication(true),
    () => setIsSendingCommunication(false),
    useCallback(
      (res, commType) => {
        const updatedEvent = transformEvent(res.data);
        setSavedEvent(updatedEvent);
        enqueueSnackbar(`Your ${commType} has successfully been deleted!`, {
          variant: 'success',
        });
      },
      [enqueueSnackbar]
    ),
    _afterSaveFail
  );

  const saveGoogleAuth = useSaveGoogleAuth(
    startTask,
    endTask,
    () => {
      enqueueSnackbar('Google Calendar integration enabled!', {
        variant: 'success',
      });
      // saveInvite();
    },
    (e) => {
      if (e?.response?.data?.errors === 'missing scopes') {
        enqueueSnackbar(
          'We were unable to connect to your calendar. Please try again and ensure calendar permissions are selected.',
          {
            variant: 'error',
          }
        );
      } else {
        enqueueSnackbar('Something went wrong logging in, please try again.', {
          variant: 'error',
        });
      }
    }
  );

  const saveMicrosoftAuth = useSaveMicrosoftAuth(
    startTask,
    endTask,
    () => {
      enqueueSnackbar('Outlook integration enabled!', {
        variant: 'success',
      });
      // saveInvite();
    },
    () => {
      enqueueSnackbar('Something went wrong logging in, please try again.', {
        variant: 'error',
      });
    }
  );

  const deleteZoomLink = useRemoveZoomMeeting(
    startTask,
    endTask,
    _afterSaveSuccess,
    () => {
      enqueueSnackbar(
        'We were unable to delete this Zoom meeting for you. Please try again.',
        { variant: 'error' }
      );
    }
  );

  const activeTab = useMemo(
    () =>
      Tabs
        ? Object.values(Tabs)?.find((t) =>
            location?.pathname?.includes(t?.slug)
          )
        : null,
    [Tabs, location]
  );

  const validationDataObj = useMemo(() => {
    const currTab = activeTab?.slug;
    switch (currTab) {
      case Tabs.edit.slug:
        return pendingEvent;
      case Tabs.surveys.slug:
        return pendingSurveys;
      default:
        return {};
    }
  }, [
    Tabs.edit.slug,
    Tabs.surveys.slug,
    activeTab?.slug,
    pendingEvent,
    pendingSurveys,
  ]);

  const validation = useFormValidation(
    activeTab?.validators,
    validationDataObj,
    ValidateEvent
  );

  const hasDateTimeErrors = useDateTimeErrors([
    endDateError,
    endTimeError,
    startDateError,
    startTimeError,
  ]);

  const hasImageChanges = useMemo(() => {
    const rightType = pendingData?.cover_image instanceof File;
    return (
      rightType ||
      (pendingData?.cover_image === '' && savedEvent?.cover_image !== null)
    );
  }, [pendingData?.cover_image, savedEvent?.cover_image]);

  const hasUnsavedSurveyChanges = useUnsavedSurveyChanges(
    pendingSurveys,
    savedEvent
  );

  const surveyTypeToSave = useMemo(() => {
    const currentSurveys = _.filter(
      [pendingSurveys?.pre_event_survey, pendingSurveys?.post_event_survey],
      (survey) => !validation.hasFieldErrors(`${survey.style}_event_survey`)
    );

    if (currentSurveys.length === 2) {
      return 'both';
    } else {
      return `${currentSurveys[0]?.style}_event_survey`;
    }
  }, [
    pendingSurveys.pre_event_survey,
    pendingSurveys.post_event_survey,
    validation,
  ]);

  const saveSurvey = useSaveSurvey(
    startTask,
    endTask,
    pendingData,
    savedEvent,
    _afterSaveSuccess,
    _afterSaveFail,
    surveyTypeToSave
  );

  const disableFields = hasStarted(savedEvent);

  const hasUnsavedMessageChanges = useUnsavedMessageChanges(
    communicationMessage,
    savedEvent
  );

  const locationType = useMemo(
    () => (activeLocationType !== undefined ? activeLocationType : null),
    [activeLocationType]
  );

  return (
    <ManagerContext.Provider
      value={{
        actions: {
          addImageError,
          analyticsExport,
          applyMulti,
          applySingle,
          clearImageError,
          clearPending,
          clearPendingCommunication: () =>
            clearPending(false, false, false, true, null, true),
          clearPendingEvent: () => clearPending(true, true),
          clearPendingInvites: () =>
            clearPending(true, false, false, true, null, true),
          clearPendingMessage: () =>
            clearPending(false, false, false, false, null, true),
          clearPendingSurvey,
          cloneEvent,
          deleteCommunication,
          deleteEvent: showDeleteModal,
          deleteSurvey,
          deleteZoomLink,
          dismissRescheduleCommsAlert,
          emailGuests,
          exportGuestList,
          handleRSVP,
          handleRsvpClick,
          reloadEvent,
          removeCalendar,
          requestAnalyticsData,
          saveEvent: useFlag('event-change-email-guests-dialog')
            ? saveEventAction
            : saveEventActionDontSendUpdate,
          saveGoogleAuth,
          saveMicrosoftAuth,
          saveRsvpPauseActive,
          saveSurvey,
          sendCommunication,
          sendSurvey,
          setActiveInvitationMethod,
          setActiveLocationType,
          setActiveTabIndex,
          setCurrentPath,
          setEndDateError,
          setEndTimeError,
          setIsAddingInvitations,
          setIsEditingName,
          setIsSendingCommunication,
          setOpenInvitationMethod,
          setPreviousPath,
          setRsvpStatus,
          setRsvpStep,
          setSavedEvent,
          setShowErrors,
          setShowPreEventSurvey,
          setStartDateError,
          setStartTimeError,
          setSurveyErrors,
          setSurveyFieldIdsToDisplayErrorsOn,
          updateGuestList,
        },
        state: {
          activeInvitationMethod,
          activeLocationType,
          activeTabIndex,
          allDisplayedHostOptions,
          canEdit,
          cover_image: pendingData?.cover_image,
          currentPath,
          disableFields,
          employeeOptions,
          endDateError,
          endTimeError,
          errors: imageErrors,
          event: pendingEvent,
          groupId,
          hasDateTimeErrors,
          hasDismissedRescheduleCommsAlert,
          hasInvalidComms,
          hasUnsavedMessageChanges,
          hasUnsavedSurveyChanges,
          hosts: allDisplayedHostSelections,
          inManagerContext: true,
          invite_message: inviteMessage,
          invites: pendingData.invites,
          isAddingInvitations,
          isEditingName,
          isLoading,
          isPublic,
          isRescheduleCommsAlertVisible,
          isSendingCommunication,
          locationType,
          manageTabs: Tabs,
          message: pendingData?.message,
          openInvitationMethod,
          previousPath,
          rsvpStatus,
          rsvpStep,
          savedEvent,
          showErrors,
          showPreEventSurvey,
          startDateError,
          startTimeError,
          surveyErrors,
          surveyFieldIdsToDisplayErrorsOn,
          surveyJustSubmitted,
          surveys: pendingSurveys,
          surveyType,
          unsavedEventChanges:
            !_.isEqual(_.omit(pendingData?.event, 'rsvp_pause_active'), {}) ||
            hasImageChanges,
          userEmployeeId: user?.employee_id,
        },
        validation,
      }}
    >
      <Head
        title={[
          ...(activeTab ? [activeTab?.label] : []),
          savedEvent?.name || 'Event',
        ].join(' | ')}
      />
      {savedEvent ? children : <FullscreenLoader show />}
      {deleteModal}
      {saveEventModal}
    </ManagerContext.Provider>
  );
};

ManagerProvider.propTypes = {
  children: PropTypes.node,
};
