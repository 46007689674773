import React, { useMemo } from 'react';
import { Box, Card, CardContent, Chip, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { CalendarBlank, User } from '@phosphor-icons/react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

import Cloudinary from 'components/Core/Cloudinary';
import GroupEvents from 'components/Groups/GroupEvents';
import GroupSideMenu from 'components/Groups/GroupSideMenu';
import PlaceholderImage from 'components/shared/PlaceholderImage';

const ViewGroup = ({
  group,
  handleJoinGroup,
  handleLeaveGroup,
  org,
  setTab,
  user,
}) => {
  const groupType = useMemo(
    () => org?.group_types?.find((g) => g?.id === group?.group_type_id),
    [group?.group_type_id, org?.group_types]
  );

  return (
    (<Box>
      <Grid alignItems='flex-start' container spacing={3}>
        <Grid
          size={{
            lg: 9,
            md: 8,
            xs: "grow"
          }}>
          <Card data-testid='group-card'>
            <Grid container minHeight={200} p={0} spacing={2}>
              <Grid size='auto'>
                {group?.cover_image ? (
                  <Cloudinary
                    data-testid='cover-image'
                    publicId={group?.cover_image}
                    secure={process.env.NODE_ENV !== 'development'}
                    style={{ maxHeight: 300 }}
                  />
                ) : (
                  <PlaceholderImage
                    color='secondary'
                    height='100%'
                    itemName={group?.name}
                    width='30vw'
                  />
                )}
              </Grid>
              <Grid size="grow">
                <CardContent>
                  <Stack direction='column' gap={2}>
                    {group?.description ? (
                      <Typography gutterBottom variant='body1'>
                        {parse(group.description)}
                      </Typography>
                    ) : null}
                    <Stack direction='row' flexWrap='wrap' gap={1}>
                      {groupType && <Chip label={`Type: ${groupType?.name}`} />}
                      <Chip
                        icon={<User />}
                        label={`${group?.employee_ids.length} members`}
                      />
                      <Chip
                        icon={<CalendarBlank />}
                        label={`${group?.event_ids.length} events`}
                      />
                    </Stack>
                  </Stack>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
          <GroupEvents group={group} user={user} />
        </Grid>
        <Grid
          position='sticky'
          top={0}
          size={{
            lg: 3,
            md: 4,
            xs: "grow"
          }}>
          <GroupSideMenu
            group={group}
            handleJoinGroup={handleJoinGroup}
            handleLeaveGroup={handleLeaveGroup}
            org={org}
            setTab={setTab}
            user={user}
          />
        </Grid>
      </Grid>
    </Box>)
  );
};

ViewGroup.propTypes = {
  employees: PropTypes.any,
  group: PropTypes.shape({
    cover_image: PropTypes.any,
    description: PropTypes.string,
    employee_ids: PropTypes.array,
    event_ids: PropTypes.array,
    group_type_id: PropTypes.any,
    name: PropTypes.string,
  }),
  handleJoinGroup: PropTypes.func,
  handleLeaveGroup: PropTypes.func,
  org: PropTypes.shape({
    group_types: PropTypes.array,
  }),
  setTab: PropTypes.func,
  user: PropTypes.any,
};

export default ViewGroup;
