import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ConditionalLoader } from 'components/Core/Loader';
import { useAuth } from 'hooks/useAuth';

const AuthenticatedRoute = ({ children }: { children: React.ReactElement }) => {
  const {
    isAuthenticated,
    userProvider: { user },
  } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      if (!isAuthenticated) {
        const pathname = window.location.href;
        sessionStorage.setItem('fromPageUrl', JSON.stringify(pathname));
        navigate('/login');
      }
    };
    checkAuthentication();
  }, [isAuthenticated, navigate]);

  return !isAuthenticated ? null : (
    <ConditionalLoader conditions={[!user]}>{children}</ConditionalLoader>
  );
};

export default AuthenticatedRoute;
