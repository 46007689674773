/* eslint-disable react/no-multi-comp */
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Chip, Stack } from '@mui/material';
import { DownloadSimple, PiggyBank } from '@phosphor-icons/react';
import dayjs from 'dayjs';

import GroupBudgetTrackerFilters from 'components/Groups/GroupBudgetTrackerFilters';
import FtnDataGrid from 'components/shared/dataGrid/FtnDataGrid';
import EmptyState from 'components/shared/EmptyState';
import SectionWrapper from 'components/shared/SectionWrapper';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { useEventTrackerData } from 'hooks/useEventTrackerData';
import { Event } from 'hooks/useGroupBudget';
import { useGroupBudgetTrackerExport } from 'hooks/useGroupBudgetTrackerExport';
import Currency from 'types/Currency';
import { getContrastColor } from 'utils/colors';
import { asCurrency } from 'utils/utils';

interface GroupBudgetTrackerProps {
  currency: Currency | undefined;
  eventsGroup: {
    events: Event[];
  };
  groupId: string;
  groupName: string;
}

const GroupBudgetTracker = ({
  currency,
  eventsGroup,
  groupId,
  groupName,
}: GroupBudgetTrackerProps) => {
  const [organization, , , , , eventTypeColors] =
    useContext(OrganizationContext);
  const navigate = useNavigate();

  const [eventTypeSelections, setEventTypeSelections] = useState<number[]>([]);
  const [search, setSearch] = useState('');

  const { mutateAsync: exportGroupExpenseTracker } =
    useGroupBudgetTrackerExport(parseInt(groupId), groupName);

  const { rows } = useEventTrackerData({
    events: eventsGroup.events,
    eventTypes: organization?.event_types || [],
    eventTypeSelections,
    search,
  });

  const columns = [
    {
      field: 'start',
      flex: 1,
      headerName: 'Event date',
      minWidth: 150,
      renderCell: (params: any) => dayjs(params.row.start).format('MM/DD/YYYY'),
    },
    {
      field: 'name',
      flex: 4,
      headerName: 'Event name',
      minWidth: 500,
    },
    {
      field: 'event_type',
      flex: 2,
      headerName: 'Event type',
      minWidth: 200,
      renderCell: (params: any) => {
        const bgcolor = eventTypeColors[params.row.event_type_id as number];
        return (
          bgcolor !== undefined && (
            <Chip
              color='primary'
              label={
                organization?.event_types?.find(
                  (t: { id?: number }) => t?.id === params.row.event_type_id
                )?.name
              }
              size='small'
              sx={
                eventTypeColors !== null
                  ? {
                      bgcolor,
                      color: getContrastColor(bgcolor),
                    }
                  : null
              }
              variant='light'
            />
          )
        );
      },
    },
    {
      align: 'right',
      field: 'budget_amount',
      flex: 2,
      headerAlign: 'right',
      headerName: 'Event budget',
      minWidth: 150,
      renderCell: (params: any) =>
        params.row.budget_amount === 0
          ? '—'
          : `${asCurrency(params.row.budget_amount, currency?.code)}`,
    },
    {
      align: 'right',
      field: 'actual_amount',
      flex: 2,
      headerAlign: 'right',
      headerName: 'Actual spend',
      minWidth: 150,
      renderCell: (params: any) =>
        params.row.items_count === 0
          ? '—'
          : `${asCurrency(params.row.actual_amount, currency?.code)}`,
    },
  ];

  const NoRowsOverlay: React.FC = () => (
    <Box pt={20}>
      <EmptyState
        buttonLabel=''
        Icon={PiggyBank}
        maxContentWidth={400}
        secondary='Tag new or existing events to this group to add them to your budget tracker.'
      />
    </Box>
  );

  return (
    <SectionWrapper
      action={
        rows?.length > 0 ? (
          <Button
            data-testid='export-expenses'
            onClick={() => exportGroupExpenseTracker()}
            startIcon={<DownloadSimple />}
          >
            Download
          </Button>
        ) : null
      }
      title='Budget tracker'
      toolbar={
        eventsGroup.events.length > 0 ? (
          <Stack
            alignItems='center'
            direction='row'
            gap={1}
            justifyContent='space-between'
          >
            <GroupBudgetTrackerFilters
              eventTypeOptions={organization?.event_types || []}
              search={search}
              setEventTypeSelections={setEventTypeSelections}
              setSearch={setSearch}
            />
          </Stack>
        ) : null
      }
    >
      <FtnDataGrid
        columns={columns}
        noRowsOverlay={NoRowsOverlay}
        onRowClick={(params) => navigate(`/event/${params.id}/budget`)}
        rows={rows}
      />
    </SectionWrapper>
  );
};

export default GroupBudgetTracker;
