import React, { useMemo, useState } from 'react';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Gauge } from '@phosphor-icons/react';
import { PropTypes } from 'prop-types';

import NpsChart from 'components/Analytics/NpsChart';
import { CalculateNPS } from 'utils/event';
import { NpsScoreDescriptions } from 'utils/nps_score';

const NpsCard = ({
  events,
  isCumulative = false,
  isFiltering,
  noEvents,
  renderWithoutCard = false,
}) => {
  const theme = useTheme();
  const isUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const npsTotals = useMemo(
    () =>
      events?.length > 0 || (noEvents && isFiltering)
        ? CalculateNPS(events)
        : null,
    [events, isFiltering, noEvents]
  );

  const npsScore = useMemo(
    () =>
      npsTotals &&
      (npsTotals.nps || npsTotals.nps === 0) &&
      Math.round(npsTotals.nps * 100),
    [npsTotals]
  );

  return (
    <>
      <Card
        {...(renderWithoutCard ? { elevation: 0, variant: 'elevation' } : {})}
        data-testid='nps-chart'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          ...(renderWithoutCard ? { p: 0 } : { flex: 1 }),
        }}
      >
        <CardHeader
          action={
            <Button
              data-testid='learn-more'
              onClick={() => setDialogIsOpen(true)}
              size='small'
            >
              Learn more
            </Button>
          }
          avatar={
            isCumulative ? null : (
              <Avatar data-testid='TODO:DATA-AVATAR-77485'>
                <Gauge size={24} />
              </Avatar>
            )
          }
          subheader={isCumulative ? 'Cumulative' : null}
          sx={{ pb: 0 }}
          title='Net Promoter Score'
        />
        <CardContent
          sx={{
            display: 'flex',
            flexGrow: 1,
            pt: 0,
            ...(isUpLg ? { minHeight: 250 } : {}),
          }}
        >
          <NpsChart
            events={events}
            isCumulative
            isFiltering={isFiltering}
            noEvents={noEvents}
          />
        </CardContent>
      </Card>
      <Dialog
        data-testid='TODO:DATA-DIALOG-24183'
        onClose={() => setDialogIsOpen(false)}
        open={dialogIsOpen}
      >
        <DialogTitle>NPS descriptions</DialogTitle>
        <DialogContent sx={{ pb: 0 }}>
          <DialogContentText>Your NPS: {npsScore}</DialogContentText>
        </DialogContent>
        <List data-testid='TODO:DATA-LIST-35684'>
          {NpsScoreDescriptions({ isSingleEvent: events?.length === 1 }).map(
            ({ max, min, primary, secondary }) => {
              const isActive =
                min && max
                  ? npsScore >= min && npsScore <= max
                  : !min
                  ? npsScore <= max
                  : npsScore >= min;
              return (
                <ListItem
                  key={primary}
                  sx={{
                    backgroundColor: isActive
                      ? theme.palette.primary.light
                      : null,
                    px: 3,
                  }}
                >
                  <ListItemText
                    primary={primary}
                    primaryTypographyProps={{ variant: 'h3' }}
                    secondary={secondary}
                    secondaryTypographyProps={{ variant: 'body1' }}
                  />
                </ListItem>
              );
            }
          )}
        </List>
      </Dialog>
    </>
  );
};

NpsCard.propTypes = {
  events: PropTypes.array,
  isCumulative: PropTypes.bool,
  isFiltering: PropTypes.bool,
  noEvents: PropTypes.bool,
  renderWithoutCard: PropTypes.bool,
};

export default NpsCard;
