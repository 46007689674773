import { useCallback, useMemo, useState } from 'react';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';

import { FromGroupId } from 'utils/event';
import { ValidateEmail } from 'utils/utils';

export const usePendingData = (defaultState) => {
  const [pendingData, setPendingData] = useState(defaultState);

  /**
   * applySingle - Update a single field within the pending data object (by path) and apply it to
   * the context's state
   */
  const applySingle = useCallback(
    (value, path) => {
      if (path) {
        const newData = _.cloneDeep(pendingData);
        _.set(newData, path, value);
        setPendingData(newData);
      }
    },
    [pendingData]
  );

  /**
   * applyMulti - Update multiple fields within the pending data object (by path) as a single
   * transaction and apply it to the context's state
   */
  const applyMulti = useCallback(
    (deltas) => {
      if (deltas && deltas.length > 0) {
        const newData = _.cloneDeep(pendingData);
        _.forEach(deltas, (delta) => {
          if (delta.length === 2) {
            _.set(newData, delta[1], delta[0]);
          }
          setPendingData(newData);
        });
      }
    },
    [pendingData]
  );

  /**
   * clearPending - Clear out a portion of the pending data set,
   * can clear out one or multiple datas, including event data, survey data, cover image, and invites
   */
  const clearPending = useCallback(
    (event, survey, cover_image, invites, surveyType, message) => {
      const clonePending = _.cloneDeep(pendingData);
      if (event) {
        clonePending.event = {};
        _.unset(clonePending, 'cover_image');
      }
      if (survey) {
        if (surveyType) {
          clonePending.surveys[surveyType] = { editing: false };
        } else {
          clonePending.surveys = {
            post_event_survey: { editing: false },
            pre_event_survey: { editing: false },
          };
        }
        clonePending.surveyType = null;
      }
      if (cover_image) {
        clonePending.cover_image = '';
      }
      if (invites) {
        clonePending.invites = defaultState.invites || {
          calendar: {},
          email: {},
          slack: {},
        };
        clonePending.invite_message = defaultState.invite_message || {
          email: {
            invite_body: '',
            invite_subject: '',
          },
          slack: {
            invite_body: '',
          },
        };
      }
      if (message) {
        clonePending.message = defaultState.message || {
          invitee_statuses: [],
          is_scheduled: false,
          message_body: '',
          message_type: '',
          scheduled_for: '',
          show_event_details_button: false,
          show_rsvp_buttons: false,
          slack_channel_ids: [],
        };
      }
      setPendingData(clonePending);
    },
    [defaultState, pendingData]
  );

  return { applyMulti, applySingle, clearPending, pendingData };
};

export const useAllRequiredSubmitted = (responses, fields) =>
  useMemo(() => {
    const areAnswered = _.chain(fields)
      .filter((field) => field?.required)
      .map((field) => {
        if (field?.id === 'email') {
          return (
            _.trim(responses[field.id])?.length > 0 &&
            ValidateEmail(responses[field.id])
          );
        } else {
          return _.trim(responses[field.id])?.length > 0;
        }
      })
      .every((field) => field)
      .value();
    return areAnswered;
  }, [responses, fields]);

export const useImageError = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [imageErrors, setErrors] = useState([]);

  const addImageError = useCallback(
    (customMessage) => {
      if (customMessage) {
        setErrors({ message: customMessage });
      } else {
        setErrors({ message: 'image upload error' });
        enqueueSnackbar(
          'Your event was saved, but there was a problem uploading your image. Please try again!',
          { variant: 'error' }
        );
      }
    },
    [setErrors, enqueueSnackbar]
  );

  const clearImageError = useCallback(() => setErrors([]), [setErrors]);

  return { addImageError, clearImageError, imageErrors };
};

export const useFormValidation = (
  validators,
  dataObj,
  objectValidator = undefined
) => {
  const fieldErrors = useMemo(() => {
    const fieldValErrors = _.chain(validators || [])
      .map((validatorObj) => {
        const currFieldValue = _.get(dataObj, validatorObj.field);

        const fieldErrors = (validatorObj.validator || (() => true))(
          currFieldValue
        );

        return {
          errors: fieldErrors,
          name: validatorObj.field,
        };
      })
      .filter((fieldValidationState) => fieldValidationState.errors !== true)
      .keyBy('name')
      .value();

    if (objectValidator) {
      const objectValErrors = _.keyBy(objectValidator(dataObj), 'field');
      return _.merge({}, fieldValErrors, objectValErrors);
    }

    return fieldValErrors;
  }, [validators, dataObj, objectValidator]);

  const getFirstError = useCallback(
    (field) =>
      _.chain(fieldErrors[field]?.errors)
        .filter((err) => err.type === 'field')
        .first()
        .value() || false,
    [fieldErrors]
  );

  const getOptionErrors = useCallback(
    (field) =>
      _.chain(fieldErrors[field]?.errors)
        .filter((err) => err.type === 'option')
        .value() || false,
    [fieldErrors]
  );

  const hasFieldErrors = useCallback(
    (field) => fieldErrors[field]?.errors?.length > 0,
    [fieldErrors]
  );

  const hasErrors = useMemo(() => !_.isEmpty(fieldErrors), [fieldErrors]);

  return {
    fieldErrors,
    getFirstError,
    getOptionErrors,
    hasErrors,
    hasFieldErrors,
  };
};

export const useNavigateFromGroup = (location, groups) => {
  const groupId = useMemo(() => {
    const id = FromGroupId(location);
    const validId = _.some(groups, (group) =>
      group.id ? group.id === id : group === id
    );
    if (validId) {
      return id;
    }
  }, [location, groups]);

  return groupId;
};
